import React from 'react'
import AppLayout from '../App/AppLayout'

const Download = () => {
  return (
    <AppLayout>
        <div>Download - Under Construction</div>
    </AppLayout>
  )
}

export default Download