import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { Search } from "../../../domain/Types/Search";
import { BASE_URL } from "../../../domain/Types/Config";

export const StoreSearchItem = async (query: Search): Promise<String[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return StoreSearchItem(query); // Retry fetching data
      }
  
      const url = `${BASE_URL}/Itemsearch`;
      const response: AxiosResponse<String[]> = await axios.post(url, query);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  
  
  export const FrequentSearchItems = async (): Promise<[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return FrequentSearchItems(); // Retry fetching data
      }
  
      const url = `${BASE_URL}/frequentsearches`;
      const response: AxiosResponse<[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };