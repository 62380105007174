import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import AdminLayout from '../../customer/Components/Layouts/Admin/AdminLayout';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Promotion } from '../../../domain/Types/Promotion'; // Assuming your types are set up
import { Menu as MenuItemType } from '../../../domain/Types/Menu';
import { fetchPromotions, mapPromotionToMenu, getMappedPromotions } from '../../../infrastructure/api/Promotions/PromotionAPI'; // API functions
import { fetchMenuData } from '../../../infrastructure/api/Menus/MenuAPI';
import { PromotionMenuMapping } from '../../../domain/Types/PromotionMenuMapping';
import Swal from 'sweetalert2';
import { MappedPromotions } from '../../../domain/Types/MappedPromotions';

const mappingSchema = z.object({
  promotion_id: z.number().min(1, 'Please select a promotion'),
  menu_id: z.number().min(1, 'Please select a menu item'),
});

type MappingFormInputs = z.infer<typeof mappingSchema>;

const SchedulePromotions = () => {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<MappingFormInputs>({
    resolver: zodResolver(mappingSchema),
  });

  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [menus, setMenus] = useState<MenuItemType[]>([]);
  const [mappedPromotions, setMappedPromotions] = useState<MappedPromotions[]>([]); // Adjusted to have clear names
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false); // Add loading state for submission

  useEffect(() => {
    const loadData = async () => {
      try {
        const promotionsData = await fetchPromotions();
        const menusData = await fetchMenuData(2); // Assuming 2 is the business ID
        const mappedData = await getMappedPromotions();
        setPromotions(promotionsData);
        setMenus(menusData);
        setMappedPromotions(mappedData);
        console.log(mappedData);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const onSubmit = async (data: MappingFormInputs) => {
    const promomap: PromotionMenuMapping = {
      menu_id: Number(data.menu_id), // Ensure the menu_id is always treated as a number
      promotion_id: data.promotion_id,
    };

    setIsSaving(true); // Start loading

    try {
      await mapPromotionToMenu(promomap);
      Swal.fire("Success!", "Promotion mapped to menu item successfully!", "success");
      // Reload mapped promotions after mapping
      const mappedData = await getMappedPromotions();
      console.log(mappedData);
      setMappedPromotions(mappedData);
    } catch (error) {
      Swal.fire("Error", "Failed to map promotion", "error");
    } finally {
      setIsSaving(false); // Stop loading
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Map Promotions to Menus
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="promotion_id"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.promotion_id}>
                      <InputLabel>Promotion</InputLabel>
                      <Select
                        {...field}
                        label="Promotion"
                      >
                        {promotions.map((promo) => (
                          <MenuItem key={promo.id} value={promo.id}>
                            {promo.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.promotion_id && <Typography variant="body2" color="error">{errors.promotion_id.message}</Typography>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="menu_id"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.menu_id}>
                      <InputLabel>Menu Item</InputLabel>
                      <Select
                        {...field}
                        label="Menu Item"
                        onChange={(e) => {
                          const value = Number(e.target.value); // Convert the string to number
                          field.onChange(value); // Pass the numeric value to react-hook-form
                        }}
                        value={field.value ? String(field.value) : ''} // Ensure value is passed as a string
                      >
                        {menus.map((menu) => (
                          <MenuItem key={menu.id} value={menu.id}>
                            {menu.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.menu_id && <Typography variant="body2" color="error">{errors.menu_id.message}</Typography>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" type="submit" disabled={isSaving}>
                  Map Promotion
                </Button>
                {isSaving && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Typography variant="h5" sx={{ mt: 4 }} gutterBottom>
          Mapped Promotions
        </Typography>

        {loading ? (
          <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Promotion Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Menu Item</TableCell>
                  <TableCell>Slug</TableCell>
                  <TableCell>Discount Amount</TableCell>
                  <TableCell>Discount Percentage</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mappedPromotions.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.promotion.name}</TableCell>
                    <TableCell>{item.promotion.description}</TableCell>                    
                    <TableCell>{item.menu.name}</TableCell>
                    <TableCell>{item.menu.slug}</TableCell>
                    <TableCell>{item.promotion.discount_amount}</TableCell>
                    <TableCell>{item.promotion.discount_percentage}%</TableCell>
                    <TableCell>{item.promotion.start_date}</TableCell>
                    <TableCell>{item.promotion.end_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>          
        )}
      </Container>
    </AdminLayout>
  );
};

export default SchedulePromotions;