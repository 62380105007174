import React from 'react'
import AppLayout from '../App/AppLayout'

const Marketing = () => {
  return (
    <AppLayout>
        <div>Marketing - Under Construction</div>
    </AppLayout>
    
  )
}

export default Marketing