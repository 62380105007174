import React, { useEffect, useState } from 'react';
import AppLayout from '../customer/Components/Layouts/App/AppLayout';
import { GetPromotion } from '../../infrastructure/api/Promotions/PromotionAPI';
import { Promotion } from '../../domain/Types/Promotion';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuCard from '../customer/Components/OtherComponents/MenuCard';
import AddToCartModal from './GeneralComponents/AddToCartModal';
import { Menu } from '../../domain/Types/Menu';
import { Grid } from '@mui/material';

function Promotionsdetails() {
  const location = useLocation();
  const { promotionId } = location.state || {};
  const [promotionDetails, setPromotionDetails] = useState<Promotion | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null);
  const [loading, setLoading] = useState(true);
  const win = window.sessionStorage;
  const userIdString = win.getItem('userid');
  const userId = userIdString ? parseInt(userIdString) : 0;
  const navigate = useNavigate();

  const handleOpenModal = (item: Menu) => {
    console.log('Opening modal with item:', item);

    let discountedPrice = item.price;
    if (promotionDetails) {
      if (promotionDetails.discount_percentage) {
        // Apply percentage discount
        discountedPrice = item.price - (item.price * promotionDetails.discount_percentage) / 100;
      } else if (promotionDetails.discount_amount) {
        // Apply fixed discount amount
        discountedPrice = Math.max(0, item.price - promotionDetails.discount_amount);
      }
    }

    const mnu: Menu = {
      ...item,
      price: discountedPrice // Apply the discounted price
    };

    setSelectedItem(mnu);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (promotionId) {
      const fetchPromotion = async () => {
        try {
          const promo: Promotion = {
              id: promotionId,
              name: '',
              start_date: '',
              end_date: '',
              business_id: 0,
              image_path: null,
              slug: null
          };
          const data = await GetPromotion(promo); // Ensure `GetPromotion` accepts `promotionId` directly
          setPromotionDetails(data);
        } catch (error) {
          console.error('Error fetching promotion details:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPromotion();
    }
  }, [promotionId]);

  if (loading) {
    return <AppLayout>Loading...</AppLayout>;
  }

  if (!promotionDetails) {
    return (
      <AppLayout>
        <h1>Promotion Details</h1>
        <p>No details available for this promotion.</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <h1>Promotion Details</h1>
      <img
        src={promotionDetails.image_path || 'https://turmericausa.com/wp-content/uploads/2023/07/Catering_mobile-banner.png'}
        alt={promotionDetails.name}
        style={{ width: '100%', maxHeight: '100px', objectFit: 'cover' }}
      />
      <h2>{promotionDetails.name}</h2>
      <p>{promotionDetails.description || 'No description available'}</p>
      <p>
        {promotionDetails.discount_percentage
          ? `Discount: ${promotionDetails.discount_percentage}%`
          : promotionDetails.discount_amount
          ? `Discount: $${promotionDetails.discount_amount}`
          : 'No discount available'}
      </p>
      <p>{`Valid from: ${promotionDetails.start_date} to ${promotionDetails.end_date}`}</p>

      <h3>Promotion Menu</h3>
      {promotionDetails.menus && promotionDetails.menus.length > 0 ? (
        <Grid container spacing={2}>
          {promotionDetails.menus.map((item: Menu, index: number) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <MenuCard
                FoodMenu={item}
                onViewOption={() => navigate('/MenuOptions')}
                onSelectmenu={() => handleOpenModal(item)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <p>No menu items associated with this promotion.</p>
      )}

      {/* Modal component */}
      {selectedItem && (
        <AddToCartModal
          open={openModal}
          handleClose={handleCloseModal}
          selectedItem={selectedItem}
          userId={userId}
        />
      )}
    </AppLayout>
  );
}

export default Promotionsdetails;