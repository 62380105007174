import { useEffect, useState } from "react";
import AppLayout from "../customer/Components/Layouts/App/AppLayout";
import { requestFCMToken } from "../../utilities/firebase";
import { Box, Grid, Card, CardContent, CardMedia, Badge, Typography, IconButton, Rating, Tooltip, Skeleton, Button, CircularProgress } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { AddShoppingCart } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { fetchPopularMenus } from "../../infrastructure/api/Menus/MenuAPI";
import { GeoSearch } from "../../domain/Types/GeoSearch";
import { Menu } from "../../domain/Types/Menu";
import { ShoppingCartState } from "../../utilities/Context/ShoppingCartContext";
import { ShoppingCart } from "../../domain/Types/ShoppingCart";
import AddToCartModal from "./GeneralComponents/AddToCartModal";
import ShoppingCartFloat from "../customer/Components/OtherComponents/ShoppingCartFloat";
import CategorySection from "../customer/Components/CommmonComponents/CategorySection";
import { SaveVisitor } from "../../infrastructure/api/UtilityAPIs/UsertrackingAPI";
import { VisitorTracker } from "../../domain/Types/VisitorTracker";
import { GetVisitorIP } from "../../infrastructure/api/UtilityAPIs/UsertrackingAPI";
import { fetchAllAddressedBusiness } from "../../infrastructure/api/Business/BusinessAPI";
import { Category } from "../../domain/Types/Category";
import { AddressBizDetailed } from "../../domain/Types/AddressBizDetailed";
import { fetchCategoriesData } from "../../infrastructure/api/Category/CategoryAPI";
import { fetchProxBusiness } from "../../infrastructure/api/BusinessAddress/BusinessAddressAPI";
import BusinessCard from "../customer/Components/OtherComponents/BusinessCard";
import { Business } from "../../domain/Types/Business";
import { fetchUserIPAndLocation } from "../../infrastructure/Services/Visitor/VisitorService";
import { SaveCustomerJourney } from "../../infrastructure/api/CustomerJourney/CustomerJourneyAPI";
import ScrollPromotion from "./GeneralComponents/ScrollPromotion";

const LandingScreen = () => {
  const [fcmToken, setFcmToken] = useState("");
  const [foodItems, setfoodItems] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [openModal, setOpenModal] = useState(false); 
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null); 
  const [userLocation, setUserLocation] = useState<{ latitude: number, longitude: number } | null>(null);
  const [category, setCategory] = useState<Category[] | null>(null);
  const [businessAdd, setBusinessAdd] = useState<AddressBizDetailed[] | null>(null);
  const win = window.sessionStorage;
  const navigate = useNavigate();
  const { dispatch } = ShoppingCartState();

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;  
  const [radius, setRadius] = useState<number>(15); // default radius to 15
  const [latLng, setLatLng] = useState<{ lat: number | null; lng: number | null }>({ lat: null, lng: null });

  const handleAddtoCart = (menu: Menu) => {
    const cartitem: ShoppingCart = {
      id: menu.id,
      user_id: userId,
      session_id: menu.business_id,
      order_id: 1,
      menu_id: menu.id,
      imagepath: menu.image_path,
      status: menu.description,
      quantity: 1,
      total: menu.price,
      name: menu.name,
      price: menu.price,
      productattributevalues: [],
      businessId: menu.business_id,
      businessName: menu.business.name
    };

    dispatch({ type: 'ADD_TO_CART', item: cartitem });
    setOpenModal(false);
  };

  const fetchBusinessesByLocation = async (latitude: number, longitude: number, radius: number) => {
    const search: GeoSearch = {
      DeliveryAddress: "",
      Category: 0,
      Latitude: latitude,
      Longitude: longitude,
      StartingPrice: 0,
      EndingPrice: 0,
      Radius: radius
    };

    try {
      const data = await fetchProxBusiness(search);
      setBusinessAdd(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    const fetchFCMToken = async () => {
        try {
            const token = await requestFCMToken();
            setFcmToken(token);
            console.log(token);
            localStorage.setItem('fcmToken', token || '');
        } catch (error) {
            console.error("Failed to get FCM Token", error);
        }
    };

    // Fetch data
    fetchFCMToken();
    fetchUserIPAndLocation(setUserLocation);
}, []);

  const fetchAllBusinesses = async () => {
    try {
      const data = await fetchAllAddressedBusiness();
      console.log(data);
      setBusinessAdd(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await fetchCategoriesData();
        setCategory(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();

    if (navigator.geolocation && radius > 0) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLng({ lat: latitude, lng: longitude });
          fetchBusinessesByLocation(latitude, longitude, radius);
        },
        (error) => {
          console.error("Error obtaining location:", error);
          fetchAllBusinesses();  // Fallback to full search if geolocation fails
        }
      );
    } else {
      fetchAllBusinesses();  // Fallback to full search if geolocation is not supported or radius is 0
      if (radius > 0) console.log("Geolocation is not supported by this browser.");
    }
  }, [radius]);  

  useEffect(() => {
    if (userLocation) {
      const search: GeoSearch = {
        DeliveryAddress: "",
        Category: 0,
        Latitude: userLocation.latitude,
        Longitude: userLocation.longitude,
        StartingPrice: 500,
        EndingPrice: 2000,
        Radius: 15
      };

      const getPopularMenus = async (search: GeoSearch) => {
        try {
          const data = await fetchPopularMenus(search);
          setfoodItems(data);
          setLoading(false); // Set loading to false after data is fetched
        } catch (error) {
          console.error("Error fetching popular menus", error);
        }
      };

      getPopularMenus(search);
    }
  }, [userLocation]);  

  const handleOpenModal = (item: Menu) => {
    setSelectedItem(item);
    setOpenModal(true);

    try {
        // Prepare the data to be sent
        const customerJourneyData = {
            user_id: 1, // Get the user ID from your state/context
            menu_id: item.id,
            business_id: item.business_id, // Assuming item has a businessId property
            stage: 'awareness', // The stage you want to log
            interaction_time: new Date().toISOString(), // Current timestamp
            metadata: JSON.stringify(item), // Any additional data (optional)
        };

        // Send the request to save the customer journey
        SaveCustomerJourney(customerJourneyData);
        console.log('Customer journey saved successfully.');
    } catch (error) {
        console.error('Error saving customer journey:', error);
    }    

  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  const handleBadgeClick = () => {
    navigate(`/ProductPage`);
  };

  const handleFoodStoreselect = (foodstore: Business) => {
    navigate(`/FoodStores/${foodstore.id}`);
  };
  

  const FoodBusinesses = () => {
    return (
    <Grid item md={12} container direction="row" spacing={2}>
      {loading ? (
        Array.from(new Array(8)).map((_, index) => (  // Render 8 skeleton items
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Skeleton variant="rectangular" width="100%" height={200} />
            <Skeleton width="60%" />
            <Skeleton width="40%" />
          </Grid>
        ))
      ) : (
        businessAdd ? businessAdd.map((BizAdd: AddressBizDetailed) => (
          <Grid item xs={12} sm={6} md={3} key={BizAdd.business.id}>
            <BusinessCard foodstore={BizAdd.business} onSelectfoodstore={handleFoodStoreselect} />
          </Grid>
        )) : (
          <Typography variant="h6">Coming Soon</Typography>
        )
      )}
  </Grid>   
  )
  }

  const FoodTile = ({ item }: { item: typeof foodItems[0] }) => {
    return (
      <Card
        sx={{
          borderRadius: '16px 16px 8px 8px',
        }}
      >
        <Box position="relative">
          <Badge
            badgeContent={`MK${item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            color="primary"
            sx={{
              position: 'absolute',
              top: 15,
              left: 30,
              zIndex: 1,
              padding: '0.5rem',
              borderRadius: '8px',
              backgroundColor: '#1976d2',
              color: 'white',
            }}
          />
          <IconButton
            aria-label="add to cart"
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              zIndex: 1,
              backgroundColor: '#ffffff',
            }}
            onClick={() => handleOpenModal(item)}
          >
            <AddShoppingCart />
          </IconButton>
          <CardMedia
            component="img"
            image={`https://app.foodhut.mw/Admin/public/images/${item.image_path}`}
            alt={item.name}
            sx={{
              height: 140,
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            onClick={() => handleOpenModal(item)}
          />
        </Box>
        <CardContent sx={{ padding: 1 }}>
          <Typography variant="h6" component="div" sx={{ marginBottom: 0.5 }}>
            {item.name}
          </Typography>
          <Rating value={item.average_rating} precision={0.5} readOnly sx={{ marginBottom: 0.5 }}/>
          <Box mt={0}>
            <Typography variant="h6" component="div" color="textPrimary" sx={{ marginBottom: 0.5 }}>
              MK {item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>
            {/*<Typography
              variant="body2"
              component="div"
              color="textSecondary"
              sx={{ textDecoration: 'line-through', marginBottom: 0 }}
            >
              MK {item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>*/}
          </Box>
        </CardContent>
      </Card>
    );
  };

  const FoodTiles = () => {
    const settings = {
      dots: true,
      infinite: foodItems.length > 1, // Disable infinite loop if only 1 item
      speed: 500,
      slidesToShow: Math.min(foodItems.length, 4), // Adjust the number of slides shown based on items
      slidesToScroll: 1,
      autoplay: foodItems.length > 1, // Disable autoplay if only 1 item
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: Math.min(foodItems.length, 2),
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
      <div>
        <span className="pl-5 font-bold">
          <Typography variant="h6">Today's Deals</Typography>
        </span>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Slider {...settings}>
              {loading ? (
                Array.from(new Array(4)).map((_, index) => (
                  <Box key={index} sx={{ padding: 2 }}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={200}
                      sx={{ borderRadius: '5%' }}
                    />
                    <Skeleton width="60%" />
                    <Skeleton width="40%" />
                  </Box>
                ))
              ) : (                
                foodItems.map((item) => (
                  <div key={item.id}>
                    <Box sx={{ padding: 2 }}>
                      <FoodTile item={item} />
                    </Box>
                  </div>
                ))
              )}
            </Slider>
          </Grid>
          <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Box sx={{ height: '100%' }}>
              <ShoppingCartFloat />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  const handleCategoryClick = (categoryName: string) => {
    navigate('/FoodCategory', { state: { categoryName } });
  };  

  const ScrollingItems = () => {
    return (
      <Box>
        <Typography variant="h4" sx={{ padding: 2 }}>
          Categories
        </Typography>
        <Box sx={{ display: 'flex', overflowX: 'auto', padding: 2 }}>
          {loading ? (
            Array.from(new Array(5)).map((_, index) => (
              <Skeleton key={index} variant="circular" width={80} height={80} sx={{ marginRight: 2 }} />
            ))
          ) : (
            foodItems.map((item) => (
              <Tooltip key={item.id} title={item.name} arrow>
                <Box
                  onClick={() => handleCategoryClick(item.name)}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    marginRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 3,
                    cursor: 'pointer',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`https://app.foodhut.mw/Admin/public/images/${item.image_path}`}
                    alt={item.name}
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
              </Tooltip>
            ))
          )}
        </Box>
      </Box>
    );
  };

  const MoreTiles = ({  }) => {
    const [visibleItems, setVisibleItems] = useState(12); // Track how many items to display
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false); // Track loading state for "Show More"
  
    // Handler to show more items when clicked
    const handleShowMore = () => {
      setVisibleItems((prev) => prev + 12); // Show 12 more items each time
    };
  
    return (
      <Box>
        <Grid container spacing={2}>
          {loading ? (
            Array.from(new Array(4)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '5%' }}
                  width="100%"
                  height={200}
                />
                <Skeleton width="60%" />
                <Skeleton width="40%" />
              </Grid>
            ))
          ) : (
            foodItems.slice(0, visibleItems).map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <FoodTile item={item} />
              </Grid>
            ))
          )}
        </Grid>
  
          {/* Show "Show More" button if there are more items to display */}
          {!loading && visibleItems < foodItems.length && (
            <Box textAlign="center" mt={2}>
              {isLoadingMore ? (
                <CircularProgress size={24} />
              ) : (
                <Button variant="contained" onClick={handleShowMore}>
                  Show More
                </Button>
              )}
            </Box>
          )}
      </Box>
    );
  };

  return (
    <AppLayout>
      <Box sx={{ marginTop: 2 }}>
        <CategorySection />
        <ScrollPromotion />
        <FoodTiles />
        <Typography variant="h4" className="font-bold mt-3" sx={{ padding: 2 }}>Food Places Near You</Typography>
        <FoodBusinesses />
      </Box>
      
      <Box sx={{ marginTop: 2 }}>
        {/*<ScrollingItems />*/}
        <Typography variant="h4" className="font-bold mt-3" sx={{ padding: 2 }}>Healthy options Near You</Typography>
        <MoreTiles />
      </Box>
      
      {selectedItem && (
      <AddToCartModal 
      open={openModal} 
      handleClose={handleCloseModal}
      selectedItem={selectedItem} 
      userId={userId}
/>  
      )}
    </AppLayout>
  );
};

export default LandingScreen;