import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { Payment } from "../../../domain/Types/Payment";
import { User } from "../../../domain/Types/User";
import { BASE_URL } from "../../../domain/Types/Config";
import { paymentoption } from "../../../domain/Types/paymentoption";
import { UserSearch } from "../../../domain/Types/UserSearch";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const fetchPaymentData = async (): Promise<Payment[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchPaymentData(); // Retry fetching data
    }

    const url = `${BASE_URL}/paymentdetails`;
    //const url = "https://app.foodhut.mw/api/paymentdetails";
    const response: AxiosResponse<Payment[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchUserPaymentData = async (user: User): Promise<Payment[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return fetchUserPaymentData(user); // Retry fetching data
      }
  
      const url = `${BASE_URL}/ShowuserPayment/${user.id}`;
      //const url = `https://app.foodhut.mw/api/ShowuserPayment/${user.id}`;
      const response: AxiosResponse<Payment[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const fetchUserPaymentOptions = async (Search: UserSearch): Promise<paymentoption[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return fetchUserPaymentOptions(Search);
      }
  
      const url = `${BASE_URL}/GetUserPaymentOptions`;
      const response: AxiosResponse<paymentoption[]> = await axios.post(url, Search);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };