import React, { useState } from 'react'; // Import useState for managing loading state
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Box, Grid, Typography, CircularProgress } from '@mui/material';
import AppLayout from '../App/AppLayout';
import Suggestion from "../../../../../assets/Suggestion.png";
import Swal from 'sweetalert2';
import { SendEmail } from '../../../../../infrastructure/api/Email/EmailAPI';
import { mail } from '../../../../../domain/Types/Email';

// Define the shape of the form data
interface FeedbackFormData {
  name: string;
  email: string;
  message: string;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Add your own email validation regex

const Feedback = () => {
  const { control, handleSubmit, formState: { errors }, reset } = useForm<FeedbackFormData>();
  
  const [loading, setLoading] = useState(false); // State to manage loading spinner

  const onSubmit: SubmitHandler<FeedbackFormData> = (data) => {
    console.log('Form Data:', data);

    const Suggestionemail: mail = {
      name: data.name,
      from: data.email,
      to: "info@foodhut.mw",
      message: data.message
    };

    const SendMail = async () => {
      setLoading(true); // Set loading to true when sending email
      try {
        await SendEmail(Suggestionemail);
        Swal.fire({
          title: 'SUCCESS',
          text: "Your Suggestion Has been Received, Thank you. We will work on it",
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire({
          title: 'ERROR',
          text: 'Error Sending Email, Please try again Later',
          icon: 'error',
          showConfirmButton: true
        });
      } finally {
        setLoading(false); // Reset loading state after email is sent
        reset(); // Reset the form
      }
    };

    SendMail();
  };

  return (
    <AppLayout>
      <Grid container spacing={4} sx={{ marginTop: "10px" }}>
        {/* Left side - Image Section */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box>
            <img
              src={Suggestion}
              alt="Suggestion Box"
              style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
            />
          </Box>
        </Grid>

        {/* Right side - Feedback Form */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" className="font-bold" component="h1" gutterBottom>
            We are Listening - <span className="font-light">Tell us what you want to see improve!!!</span>
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {/* Name Field */}
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name?.message && "Name is required"}
                />
              )}
            />

            {/* Email Field */}
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email address is required',
                pattern: {
                  value: emailRegex,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email?.message && "Email is required"}
                />
              )}
            />

            {/* Message Field */}
            <Controller
              name="message"
              control={control}
              defaultValue=""
              rules={{ required: 'Message is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Message"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  error={!!errors.message}
                  helperText={errors.message?.message && "Message is required"}
                />
              )}
            />

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
              disabled={loading} // Disable button while loading
            >
              {loading ? <CircularProgress size={24} /> : 'Submit Feedback'} {/* Show spinner if loading */}
            </Button>
          </form>
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default Feedback;