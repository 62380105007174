import { useParams, useNavigate, useLocation } from 'react-router-dom';
import AppLayout from '../customer/Components/Layouts/App/AppLayout';
import { useMediaQuery, Button, CircularProgress, Box, Grid, Card, CardContent, CardMedia, Badge, Typography, IconButton, Rating, Tooltip, Skeleton } from '@mui/material';
import { AddShoppingCart, DashboardRounded } from '@mui/icons-material';
import Footer from '../customer/Components/Layouts/Customer/Footer';
import { useEffect, useState } from 'react';
import { Business } from "../../domain/Types/Business";
import { fetchCategoriesData } from '../../infrastructure/api/Category/CategoryAPI';
import { fetchBusinessbyCategory, fetchBusinessData } from '../../infrastructure/api/Business/BusinessAPI';
import { fetchAllAddressedBusiness } from '../../infrastructure/api/Business/BusinessAPI';
import { fetchAllAddressedBusinessbyCat } from '../../infrastructure/api/Business/BusinessAPI';
import { Category } from "../../domain/Types/Category";
import CustomerLayout from '../customer/Components/Layouts/Customer/CustomerLayout';
import FoodhutChip from '../customer/Components/OtherComponents/FoodhutChip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import BusinessCard from '../customer/Components/OtherComponents/BusinessCard';
import ShoppingCartFloat from '../customer/Components/OtherComponents/ShoppingCartFloat';
import { Search } from '../../domain/Types/Search';
import FoodStoreSearchForm from '../customer/Components/Forms/FoodStoreSearchForm';
import { fetchProxBusiness } from '../../infrastructure/api/BusinessAddress/BusinessAddressAPI';
import { GeoSearch } from '../../domain/Types/GeoSearch';
import { AddressBizDetailed } from '../../domain/Types/AddressBizDetailed';
import comingsoon from "../../assets/comingsoon.png";

function FoodCategory() {
    const location = useLocation();
    const { categoryName, categoryId } = location.state || {};
    const [foodStoreItems, setfoodStore] = useState([]);
    const [error, setError] = useState<string | null>(null);
    const { Category: initialFoodstorecat } = useParams<{ Category: string }>();
    const [business, setBusiness] = useState<Business[] | null>(null);
    const [businessAdd, setBusinessAdd] = useState<AddressBizDetailed[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [category, setCategory] = useState<Category[] | null>(null);
    const [showAllChips, setShowAllChips] = useState(false);
    const [foodstorecat, setFoodstorecat] = useState<string | undefined>(initialFoodstorecat);
    const [latLng, setLatLng] = useState<{ lat: number | null; lng: number | null }>({ lat: null, lng: null });
    const [radius, setRadius] = useState<number>(0); // default radius to 0
    const [searchTriggered, setSearchTriggered] = useState<boolean>(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchBusinessesByLocation = async (latitude: number, longitude: number, radius: number, categoryId: number) => {      
        const search: GeoSearch = {
            DeliveryAddress: "",
            Category: categoryId,
            Latitude: latitude,
            Longitude: longitude,
            StartingPrice: 0,
            EndingPrice: 0,
            Radius: radius
        };
    
        try {
            const data = await fetchProxBusiness(search);
            console.log(data);
            setBusinessAdd(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const fetchAllBusinessesByCat = async (search: GeoSearch) => {
      try {
          const data = await fetchAllAddressedBusinessbyCat(search);
          console.log(data);
          setBusinessAdd(data);
      } catch (error) {
          console.error('Error fetching data:', error);
      } finally {
          setLoading(false);
      }
  };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await fetchCategoriesData();
                setCategory(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
        const search: GeoSearch = {
          DeliveryAddress: "",
          Category: categoryId,
          Latitude:0,
          Longitude: 0,
          StartingPrice: 0,
          EndingPrice: 0,
          Radius: radius
      };

        if (navigator.geolocation && radius > 0) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatLng({ lat: latitude, lng: longitude });
                    fetchBusinessesByLocation(latitude, longitude, radius, categoryId);
                },
                (error) => {

                    console.error("Error obtaining location:", error);
                    fetchAllBusinessesByCat(search);  // Fallback to full search if geolocation fails
                }
            );
        } else {
            fetchAllBusinessesByCat(search);  // Fallback to full search if geolocation is not supported or radius is 0
        }
    }, [radius, foodstorecat]);

    // Trigger search whenever categoryId changes
    useEffect(() => {
          setLoading(true);  // Set loading state on each search
          const search: GeoSearch = {
            DeliveryAddress: "",
            Category: categoryId,
            Latitude:0,
            Longitude: 0,
            StartingPrice: 0,
            EndingPrice: 0,
            Radius: radius
        };
  
          if (navigator.geolocation && radius > 0) {
              navigator.geolocation.getCurrentPosition(
                  (position) => {
                      const { latitude, longitude } = position.coords;
                      setLatLng({ lat: latitude, lng: longitude });                      
                      fetchBusinessesByLocation(latitude, longitude, radius, categoryId);
                  },
                  (error) => {
                      console.error("Error obtaining location:", error);
                      fetchAllBusinessesByCat(search);  // Fallback if geolocation fails
                  }
              );
          } else {
            fetchAllBusinessesByCat(search);  // Fallback to full search if geolocation is not available
          }
      }, [categoryId, radius]);  // Add categoryId as a dependency to re-fetch on category change  


    const handleFoodStoreselect = (foodstore: Business) => {
        navigate(`/FoodStores/${foodstore.id}`);
    };

    const MoreTiles = () => {
      return (
          <Box>
              <Grid container spacing={2} mt={2}>
                  {loading ? (
                      Array.from(new Array(25)).map((_, index) => (
                          <Grid item xs={12} sm={6} md={2.4} key={index}>
                              {/* Skeleton placeholders */}
                              <Skeleton variant="rectangular" width="100%" height={240} animation="wave" sx={{ borderRadius: "16px 16px 8px 8px"}} />
                              <Skeleton width="60%" animation="wave" sx={{ borderRadius: "8px 8px 8px 8px"}} />
                              <Skeleton width="40%" animation="wave" sx={{ borderRadius: "8px 8px 8px 8px"}} />
                          </Grid>
                      ))
                  ) : businessAdd && businessAdd.length > 0 ? (
                      businessAdd.map((BizAdd: AddressBizDetailed) => (
                          <Grid item xs={12} sm={6} md={2.4} key={BizAdd.business.id}>
                              <BusinessCard foodstore={BizAdd.business} onSelectfoodstore={handleFoodStoreselect} />
                          </Grid>
                      ))
                  ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          height="50vh"
                          width={"100%"}
                          textAlign="center"
                          justifyItems={"center"}
                        >
                          <Typography variant="h3" mb={2}>
                          {categoryName}                          
                          </Typography>
                          <img src={comingsoon} alt="Coming Soon" style={{ width: '20%', height: 'auto' }}  />

                        </Box>
                  )}
              </Grid>
          </Box>
      );
  };  

    return (
        <>
            <AppLayout>                
                <MoreTiles />            
            </ AppLayout>
        </>
    );
}

export default FoodCategory;