// visitorService.js
import { getLocation } from '../../../utilities/GetLocation';
import { GetVisitorIP, SaveVisitor } from '../../api/UtilityAPIs/UsertrackingAPI';
import { generateSessionID, getDeviceType,getBrowserInfo, getCookieConsent } from '../../../utilities/GetLocation';
import { Location } from '../../../domain/Types/Location';

export const fetchUserIPAndLocation = async (setUserLocation: (location: Location) => void) => {
    try {
        const userIP = await GetVisitorIP();
        const location = await getLocation();

        if (location?.latitude && location?.longitude) {
            setUserLocation({ latitude: location.latitude, longitude: location.longitude });


            const currentPage = window.location.href;
            const localhostURL = 'http://localhost:3000/';

            if (currentPage !== localhostURL) {
            const visitor = {
                ip_address: userIP,
                user_agent: navigator.userAgent,
                referrer: document.referrer || null,
                page_visited: window.location.href,
                landing_page: document.referrer || null,
                exit_page: null,
                visited_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
                duration: null,
                scroll_depth: null,
                user_id: null,
                session_id: generateSessionID(),
                conversion_status: false,
                geolocation_data: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                },
                device_type: getDeviceType(),
                browser_info: getBrowserInfo(),
                time_spent_on_page: null,
                event_actions: [],
                cookie_consent: getCookieConsent(),
                created_at: "", // Handled by backend timestamps
                updated_at: "", // Handled by backend timestamps
            };

            await SaveVisitor(visitor);
        }
        }
    } catch (error) {
        console.error("Failed to save Visitor", error);
    }
};


export const fetchVisitByDate = async (date: Date) => {
    try {
        // Format the date to match the format your backend expects (e.g., 'YYYY-MM-DD')
        const formattedDate = date.toISOString().split('T')[0];

        // Replace '/api/visits' with your backend endpoint for fetching visits by date
        const response = await fetch(`/api/visits?date=${formattedDate}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching visits: ${response.statusText}`);
        }

        const visits = await response.json();
        console.log('Visits fetched successfully:', visits);
        return visits;
    } catch (error) {
        console.error('Failed to fetch visits by date', error);
    }
};
