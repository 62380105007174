import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { BusinessPayment } from "../../../domain/Types/BusinessPayment";
import { Business } from "../../../domain/Types/Business";
import { BASE_URL } from "../../../domain/Types/Config";

axiosRetry(axios, {
    retries: 3, // Number of retries
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
    shouldResetTimeout: true // Reset timeout on retries
  });

  interface BusinessObj {
    BusinessID: number;
  }

  export const SaveBusinessPayment = async (paymentdetail: BusinessPayment): Promise<BusinessPayment[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return SaveBusinessPayment(paymentdetail);
      }
  
      const url = `${BASE_URL}/businesspayment`;
      const response: AxiosResponse<BusinessPayment[]> = await axios.post(url, paymentdetail);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const GetBusinessPayment = async (BusinessID: BusinessObj): Promise<BusinessPayment[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return GetBusinessPayment(BusinessID);
      }
  
      const url = `${BASE_URL}/Getbusinesspayments`;
      const response: AxiosResponse<BusinessPayment[]> = await axios.post(url, BusinessID);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };