import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DeliveryService from '../../../../../assets/DeliveryService.png';
import ordersuccess from '../../../../../assets/ordersuccess.png';
import OnlineShopping from '../../../../../assets/OnlineShopping.png';
import FoodFootersupport from '../../../../../assets/FoodFootersupport.png';
import AndroidDownload from '../../../../../assets/AndroidDownload.png';

const Footer = () => {

    const navigate = useNavigate();
    const handleCategoryClick = (categoryName: string, categoryId: number) => {
        navigate('/FoodCategory', { state: { categoryName, categoryId } });
      };

  return (
    <>
                    <div style={{ borderTop: '1px solid #ccc' }}></div>
                    {/*<!-- Begin Footer Area -->*/}
                    <div className="footer mt-15">
                        {/*<!-- Begin Footer Static Top Area -->*/}
                        <div className="footer-static-top">
                            <div className="container">
                                {/*<!-- Begin Footer Shipping Area -->*/}
                                <div className="footer-shipping pb-55 pb-xs-25">
                                    <div className="row">
                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-sm-55 pb-xs-55">
                                            <div className="li-shipping-inner-box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '100%' }}>
                                                <div className="shipping-icon" style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <img src={DeliveryService} alt="Delivery" style={{ width: '70%', height: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>Fast Reliable Delivery</h2>
                                                    <p>And free returns. See checkout for delivery dates.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}

                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-sm-55 pb-xs-55">
                                            <div className="li-shipping-inner-box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '100%' }}>
                                                <div className="shipping-icon" style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <img src={ordersuccess} alt="Delivery" style={{ width: '70%', height: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>Safe Payment</h2>
                                                    <p>Pay with the world's most popular and secure payment methods.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}

                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-xs-30">
                                            <div className="li-shipping-inner-box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '100%' }}>
                                                <div className="shipping-icon" style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <img src={OnlineShopping} alt="Delivery" style={{ width: '70%', height: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>Shop with Confidence</h2>
                                                    <p>Our Buyer Protection covers your purchase from click to delivery.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}

                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}
                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-xs-30">
                                            <div className="li-shipping-inner-box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '100%' }}>
                                                <div className="shipping-icon" style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <img src={FoodFootersupport} alt="Delivery" style={{ width: '70%', height: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>24/7 Help Center</h2>
                                                    <p>Have a question? Call a Specialist or chat online.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}
                                    </div>
                                </div>
                                {/*<!-- Footer Shipping Area End Here -->*/}
                            </div>
                        </div>
                        {/*<!-- Footer Static Top Area End Here -->*/}
                        {/*<!-- Begin Footer Static Middle Area -->*/}
                        <div className="footer-static-middle">
                            <div className="container">
                                <div className="footer-logo-wrap pt-50 pb-35">
                                    <div className="row">
                                        {/*<!-- Begin Footer Block Area -->*/}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div 
                                            className="footer-block" 
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
                                            >
                                                <h3 className="footer-block-title" style={{ marginBottom: '2px' , marginTop: '10px' }}>Categories</h3>
                                                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                    <li>
                                                        <a 
                                                            href="#" 
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default action
                                                                handleCategoryClick("Restaurant", 1); // Handle click and navigation
                                                            }} 
                                                            className="no-underline"
                                                            style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                        >
                                                            Restaurants
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a 
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default action
                                                                handleCategoryClick("Supermarkets", 3); // Handle click and navigation
                                                            }} 
                                                            className="no-underline"
                                                            style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                        >
                                                            Supermarkets
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a 
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default action
                                                                handleCategoryClick("FoodTruck", 2); // Handle click and navigation
                                                            }} 
                                                            className="no-underline"
                                                            style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                        >
                                                            Food Trucks
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a 
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default action
                                                                handleCategoryClick("Bakeries", 11); // Handle click and navigation
                                                            }} 
                                                            className="no-underline"
                                                            style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                        >
                                                            Bakeries
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Our Services */}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div 
                                            className="footer-block" 
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
                                            >
                                                <h3 className="footer-block-title" style={{ marginBottom: '2px' , marginTop: '10px'}}>Our Services</h3>
                                                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                    <li>
                                                    <a href="/FoodOrdering" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                            Food Ordering
                                                        </a>
                                                    </li>
                                                    <li>
                                                    <a href="/DeliveryService" 
                                                    className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                        Delivery Service</a></li>
                                                    <li>
                                                    <a href="/Marketing" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                            Marketing</a></li>
                                                    <li>
                                                    <a href="/Advertising" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                    Advertising</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Resources */}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div 
                                            className="footer-block" 
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
                                            >
                                                <h3 className="footer-block-title" style={{ marginBottom: '2px' , marginTop: '10px'}}>Resources</h3>
                                                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                    <li>
                                                    <a href="/AboutUs" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                        About Us</a></li>
                                                    <li>
                                                    <a href="/Affiliates" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                            Affiliates</a></li>
                                                    <li>
                                                    <a href="/ContactUs" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                            Contact Us</a></li>
                                                            <li>
                                                    <a href="/Feedback" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                            Give Us Feedback</a></li>                                                            
                                                    <li>
                                                            <a href="/PlanandPricing" className="no-underline"
                                                            style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                            target="_blank"
                                                            >
                                                            Plan and Pricing</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Our Company */}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div 
                                            className="footer-block" 
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
                                            >
                                                <h3 className="footer-block-title" style={{ marginBottom: '2px' , marginTop: '10px'}}>Our Company</h3>
                                                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                    <li>
                                                    <a href="/TermsCondition" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                            Terms and Condition</a></li>
                                                    <li>
                                                    <a href="/PrivacyPolicy" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >                                                     
                                                        Privacy Policy
                                                        </a></li>
                                                    <li>
                                                    <a href="/SupportPolicy" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                        Support Policy</a></li>
                                                    <li>
                                                    <a href="/RefundPolicy" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                        Refund Policy</a></li>
                                                    <li>
                                                    <a href="/FAQs" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >
                                                        FAQs</a></li>
                                                    <li>
                                                    <a href="/Download" className="no-underline"
                                                    style={{ display: 'block', color: "#000", marginBottom: '2px' }}
                                                    target="_blank"
                                                    >Download</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Download Android */}
                                        <div className="col-lg-4">
                                            <div 
                                            className="footer-block" 
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
                                            >
                                                {/*<h3 className="footer-block-title" style={{ marginBottom: '5px' , marginTop: '10px'}}>Download Android</h3>
                                                
                                                 Centering the image */}
                                                {/*<div 
                                                style={{ 
                                                    marginBottom: '15px', 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    width: '100%' 
                                                }}
                                                >
                                                    <img 
                                                    src={AndroidDownload} 
                                                    alt="Download Android" 
                                                    style={{ width: '70%', height: 'auto' }} 
                                                    />
                                                </div>*/}
                                                
                                                {/* Social Links */}
                                                <ul 
                                                className="social-link" 
                                                style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: 0 }}
                                                >
                                                    <li className="twitter">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="Twitter">
                                                            <i className="fa fa-twitter"></i>
                                                        </a>
                                                    </li>
                                                    <li className="rss">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="RSS">
                                                            <i className="fa fa-rss"></i>
                                                        </a>
                                                    </li>
                                                    <li className="instagram">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="Instagram">
                                                            <i className="fa fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                    <li className="facebook">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="Facebook">
                                                            <i className="fa fa-facebook"></i>
                                                        </a>
                                                    </li>
                                                    <li className="youtube">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="YouTube">
                                                            <i className="fa fa-youtube"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<!-- Footer Static Middle Area End Here -->*/}
                    </div>
                    {/*<!-- Footer Area End Here -->*/} 
                    <footer style={{ padding: '20px', textAlign: 'center', backgroundColor: '#f1f1f1' }}>
                        <Typography variant="body1">© 2024 FoodHut. All Rights Reserved.</Typography>
                    </footer>
    </>
  )
}

export default Footer