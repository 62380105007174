import AppLayout from './Components/Layouts/App/AppLayout'
import CustomerLayout from './Components/Layouts/Customer/CustomerLayout'

const Favourites = () => {
  return (
    <AppLayout>
    <p className="py-11">
    Favourites
    </p>
</ AppLayout>
  )
}

export default Favourites