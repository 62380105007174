import React, { useEffect, useState } from 'react';
import FHPortalLayout from '../Layouts/FHPortalLayout';
import {
  Typography,
  Paper,
  Grid,
  Box,
  CircularProgress,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { PieChart, Pie, BarChart, Bar, Tooltip, Legend, Cell } from 'recharts';

// Sample data structure for demographics
interface DemographicData {
  gender: string;
  ageGroup: string;
  location: string;
  shopId: string;
  productId: string;
  visitCount: number;
}

const Demographics = () => {
  const [demographics, setDemographics] = useState<DemographicData[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedShop, setSelectedShop] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('');

  // Sample shops and products for filtering
  const shops = [{ id: 'shop1', name: 'Shop 1' }, { id: 'shop2', name: 'Shop 2' }];
  const products = [{ id: 'prod1', name: 'Product 1' }, { id: 'prod2', name: 'Product 2' }];
  const countries = ['USA', 'Canada', 'UK', 'Australia'];

  useEffect(() => {
    const fetchDemographics = async () => {
      // Replace this with your actual data fetching logic
      const demoData: DemographicData[] = [
        { gender: 'Male', ageGroup: '18-24', location: 'New York', shopId: 'shop1', productId: 'prod1', visitCount: 120 },
        { gender: 'Female', ageGroup: '25-34', location: 'California', shopId: 'shop2', productId: 'prod2', visitCount: 150 },
        { gender: 'Other', ageGroup: '35-44', location: 'Texas', shopId: 'shop1', productId: 'prod1', visitCount: 90 },
        // Add more demographic data as needed
      ];

      // Simulate a network request
      setTimeout(() => {
        setDemographics(demoData);
        setLoading(false);
      }, 1000);
    };

    fetchDemographics();
  }, []);

  if (loading) {
    return (
      <FHPortalLayout>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </FHPortalLayout>
    );
  }

  const filteredDemographics = demographics.filter(demo => {
    return (
      (selectedShop ? demo.shopId === selectedShop : true) &&
      (selectedProduct ? demo.productId === selectedProduct : true) &&
      (selectedCountry ? demo.location === selectedCountry : true)
    );
  });

  // Calculate gender distribution
  const genderData = filteredDemographics.reduce((acc, curr) => {
    acc[curr.gender] = (acc[curr.gender] || 0) + curr.visitCount;
    return acc;
  }, {} as Record<string, number>);

  const genderChartData = Object.entries(genderData).map(([key, value]) => ({ name: key, value }));

  // Calculate age group distribution
  const ageGroupData = filteredDemographics.reduce((acc, curr) => {
    acc[curr.ageGroup] = (acc[curr.ageGroup] || 0) + curr.visitCount;
    return acc;
  }, {} as Record<string, number>);

  const ageGroupChartData = Object.entries(ageGroupData).map(([key, value]) => ({ name: key, value }));

  return (
    <FHPortalLayout>
      <Typography variant="h4" gutterBottom>
        User Visit Demographics
      </Typography>

      {/* Filter Controls */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Shop</InputLabel>
            <Select value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}>
              <MenuItem value=""><em>All</em></MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.id} value={shop.id}>{shop.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Product</InputLabel>
            <Select value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
              <MenuItem value=""><em>All</em></MenuItem>
              {products.map((product) => (
                <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
              <MenuItem value=""><em>All</em></MenuItem>
              {countries.map((country) => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Display Demographics Data */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>Gender Distribution</Typography>
              <PieChart width={300} height={300}>
                <Pie data={genderChartData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
                  {genderChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={index === 0 ? '#0088FE' : '#00C49F'} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>Age Group Distribution</Typography>
              <BarChart width={300} height={300} data={ageGroupChartData}>
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </FHPortalLayout>
  );
};

export default Demographics;