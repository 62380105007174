import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { CustomerJourney } from "../../../domain/Types/CustomerJourney";
import { BASE_URL } from "../../../domain/Types/Config";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const fetchCustomerJourneyData = async (): Promise<CustomerJourney[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return fetchCustomerJourneyData(); // Retry fetching data
      }
  
      const url = `${BASE_URL}/Customerjourney`;
      const response: AxiosResponse<CustomerJourney[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const SaveCustomerJourney = async (CustomerStage: CustomerJourney): Promise<CustomerJourney> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return SaveCustomerJourney(CustomerStage); // Retry fetching data
      }
  
      const url = `${BASE_URL}/Customerjourney`;
      const response: AxiosResponse<CustomerJourney> = await axios.post(url, CustomerStage);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const FetchBusinessCustomerJourney = async (CustomerStage: CustomerJourney): Promise<CustomerJourney[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return FetchBusinessCustomerJourney(CustomerStage); // Retry fetching data
      }
  
      const url = `${BASE_URL}/Customerjourney`;
      const response: AxiosResponse<CustomerJourney[]> = await axios.post(url, CustomerStage);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };