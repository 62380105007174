import React from 'react'
import AppLayout from '../App/AppLayout'

const DeliveryService = () => {
  return (
    <AppLayout>
      <div>DeliveryService - Under Construction</div>
    </AppLayout>
    
  )
}

export default DeliveryService