import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchMenuBySlug } from '../../infrastructure/api/Menus/MenuAPI';
import { Menu } from '../../domain/Types/Menu';
import { Box, Typography, Skeleton, CardMedia, Button, Rating, Chip } from '@mui/material';
import AppLayout from './Components/Layouts/App/AppLayout';
import { Search } from '../../domain/Types/Search';
import AddToCartModal from '../app/GeneralComponents/AddToCartModal';
import { ShoppingCartState } from '../../utilities/Context/ShoppingCartContext';
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import ShoppingCartFloat from './Components/OtherComponents/ShoppingCartFloat';

const MenuPage = () => {
  const { menuSlug } = useParams<{ menuSlug: string }>();
  const [menu, setMenu] = useState<Menu | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null);
  const win = window.sessionStorage;
  const userIdString = win.getItem('userid');
  const userId = userIdString ? parseInt(userIdString) : 0;

  const { dispatch } = ShoppingCartState();

  useEffect(() => {
    const searchCriteria: Search = {
      search: '',
      slug: menuSlug || '',
      BusinessCategory: '',
      location: '',
      foodtype: '',
      minimumPrice: 0,
      maximumprice: 0,
    };

    const fetchMenu = async () => {
      try {
        const result = await fetchMenuBySlug(searchCriteria);
        setMenu(result);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    if (menuSlug) {
      fetchMenu();
    }
  }, [menuSlug]);

  const handleAddtoCart = (menu: Menu) => {
    const cartitem: ShoppingCart = {
      id: menu.id,
      user_id: userId,
      session_id: menu.business_id,
      order_id: 1,
      menu_id: menu.id,
      imagepath: menu.image_path,
      status: menu.description,
      quantity: 1,
      total: menu.price,
      name: menu.name,
      price: menu.price,
      productattributevalues: [],
      businessId: menu.business_id,
      businessName: menu.business.name,
    };

    dispatch({ type: 'ADD_TO_CART', item: cartitem });
  };

  const handleOpenModal = (item: Menu) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  if (loading) {
    return (
      <AppLayout>
        <Box sx={{ padding: 2 }}>
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} />
        </Box>
      </AppLayout>
    );
  }

  if (error) {
    return (
      <AppLayout>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Left Column: Menu Details */}
        <Box sx={{ flex: 3, padding: 2 }}>
          {menu ? (
            <>
              <CardMedia
                component="img"
                height="300"
                image={`https://app.foodhut.mw/Admin/public/images/${menu.image_path}`} 
                alt={menu.name}
                sx={{
                  borderRadius: 2,
                  width: { xs: '100%', sm: '60%', md: '40%' },
                  height: 160,
                }}
              />

              <Typography variant="h4" component="h1" sx={{ marginTop: 2 }}>
                {menu.name}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1 }}>
                {menu.description}
              </Typography>

              <Typography variant="h6" component="p" sx={{ marginTop: 2 }}>
                Price: MK {menu.price}
              </Typography>

              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                <Chip
                  label={menu.status === "available" ? "Available" : "Not Available"}
                  sx={{
                    backgroundColor: menu.status === "available" ? 'green' : 'red',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  size="small"
                />
              </Typography>

              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                Average Rating: 
                <Rating value={menu.average_rating} precision={0.5} readOnly />
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={() => handleOpenModal(menu)}
              >
                Add to Cart
              </Button>
            </>
          ) : (
            <Typography variant="h6">Menu not found.</Typography>
          )}
        </Box>

        {/* Right Column: Shopping Cart Float */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'relative',
            height: '100%',
            '@media (max-width:900px)': {
              display: 'none', // hide on smaller screens
            },
          }}
        >
          <ShoppingCartFloat />
        </Box>
      </Box>

      {/* Add to Cart Modal */}
      {selectedItem && (
        <AddToCartModal
          open={openModal}
          handleClose={handleCloseModal}
          selectedItem={selectedItem}
          userId={userId}
        />
      )}
    </AppLayout>
  );
};

export default MenuPage;