import React from 'react';
import { Box, Typography, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Divider, Grid } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import DriverHome from './Driverhome';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import DriverLayout from './Components/Layouts/DriverLayout';

// Register the required components in Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DriverEarnings = () => {
  const weeklyEarningsData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Earnings',
        data: [150, 200, 175, 220, 300, 275, 180],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const recentDeliveries = [
    { id: 1, date: '2024-10-01', amount: '$20.00', status: 'Completed' },
    { id: 2, date: '2024-10-02', amount: '$18.50', status: 'Completed' },
    { id: 3, date: '2024-10-03', amount: '$22.75', status: 'Completed' },
    { id: 4, date: '2024-10-04', amount: '$15.00', status: 'Completed' },
  ];

  return (
    <DriverLayout>
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Driver Earnings
      </Typography>

      {/* Earnings Summary */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Total Earnings
              </Typography>
              <Typography variant="h4" color="primary">
                $1750.00
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Weekly Earnings
              </Typography>
              <Typography variant="h4" color="primary">
                $450.00
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Number of Deliveries
              </Typography>
              <Typography variant="h4" color="primary">
                45
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Weekly Earnings Chart */}
      <Typography variant="h6" gutterBottom>
        Weekly Earnings
      </Typography>
      <Bar data={weeklyEarningsData} />

      <Divider sx={{ my: 4 }} />

      {/* Recent Deliveries Table */}
      <Typography variant="h6" gutterBottom>
        Recent Deliveries
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recentDeliveries.map((delivery) => (
            <TableRow key={delivery.id}>
              <TableCell>{delivery.date}</TableCell>
              <TableCell>{delivery.amount}</TableCell>
              <TableCell>{delivery.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
    </DriverLayout>
  );
};

export default DriverEarnings;