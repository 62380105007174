import { useState } from "react";
import { TextField, Button, FormControl } from "@mui/material";
import CustomerLayout from "../Components/Layouts/Customer/CustomerLayout";
import { Link } from "react-router-dom";
import { User } from "../../../domain/Types/User";
import AppLayout from "../Components/Layouts/App/AppLayout";

function UpdatePersonalInformation() {
  const win = window.sessionStorage;

  const [user, setUser] = useState<User>({
    id: 0,
    business_id:0,
    firstname: win.getItem("fname") || "",
    lastname: win.getItem("lname") || "",
    email: win.getItem("email") || "",
    email_verified_at: "",
    password: "",
    device_token: "",
    remember_token: "",
    acl: "",
    created_at: "",
    updated_at: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Submit the updated user information to your backend or handle it accordingly
    console.log("Updated User Information:", user);
  };

  return (
    <AppLayout>
      <span className="font-bold">Personal Information</span>
      <br />
      Welcome {user.firstname} {user.lastname}
      <ul>
        <li><Link to="/PersonalInfo">Personal Information</Link></li>
        <li><Link to="/PaymentInfo">Payment Information</Link></li>
        <li><Link to="/DeliveryInfo">Delivery Information</Link></li>
      </ul>

      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="First Name"
            name="firstname"
            value={user.firstname}
            onChange={handleChange}
            fullWidth
            required
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Last Name"
            name="lastname"
            value={user.lastname}
            onChange={handleChange}
            fullWidth
            required
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Email"
            type="email"
            name="email"
            value={user.email}
            onChange={handleChange}
            fullWidth
            required
          />
        </FormControl>

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update Information
        </Button>
      </form>
    </AppLayout>
  );
}

export default UpdatePersonalInformation;