import { useEffect, useState } from 'react';
import { fetchBusinessData } from "../../infrastructure/api/Business/BusinessAPI";
import { Business } from "../../domain/Types/Business";
import { Category } from "../../domain/Types/Category";
import { fetchCategoriesData } from "../../infrastructure/api/Category/CategoryAPI";
import DriverLayout from "./Components/Layouts/DriverLayout";

const DriverHome = () => {
  const [business, setBusiness] = useState<Business[] | null>(null);
  const [category, setCategory] = useState<Category[] | null>(null);

  useEffect(() => {
    if(business){
      console.log("Data Already Available")
    }
    else
    {
      const fetchData = async () => {
        try {
          const data = await fetchBusinessData();
          setBusiness(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }

    if(category){
      console.log(category)
    }
    else
    {
      const fetchData = async () => {
        try {
          const data = await fetchCategoriesData();
          setCategory(data);
          console.log(category)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }    
  }, []);


  return (
    <DriverLayout>
      Welcome Driver
    </DriverLayout>
  );
};

export default DriverHome;