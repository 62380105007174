import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { BASE_URL } from "../../../domain/Types/Config";
import { VisitorTracker } from "../../../domain/Types/VisitorTracker";
import { VisitorLocation } from "../../../domain/Types/VisitorLocation";

axiosRetry(axios, {
    retries: 3, // Number of retries
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
    shouldResetTimeout: true // Reset timeout on retries
  });
  

  export const SaveVisitor = async (visitor: VisitorTracker): Promise<VisitorTracker> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return SaveVisitor(visitor); // Retry fetching data
      }
      
      console.log(visitor);
      const url = `${BASE_URL}/visitorstats`;
      const response: AxiosResponse<VisitorTracker> = await axios.post(url,visitor);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const GetVisitorIP = async (): Promise<string> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return GetVisitorIP(); // Retry fetching data
      }
      
      const url = "https://api.ipify.org?format=json";
      const response: AxiosResponse<{ ip: string }> = await axios.get(url);
      console.log(response.data.ip);
      return response.data.ip; // Return the IP address from the response
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const GetVisitsByDate = async (): Promise<string> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return GetVisitsByDate(); // Retry fetching data
      }
      
      
      const url = `${BASE_URL}/visitsbyDate`;
      const response: AxiosResponse<string> = await axios.post(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const TranslateIPToLocation = async (ip: string): Promise<VisitorLocation> => {
    try {
      const response = await axios.get(`https://ipinfo.io/${ip}/json`);
      const { city, region, country, loc: coordinates } = response.data; // Renaming "loc" to "coordinates"
      
      return { city, region, country, coordinates };
    } catch (error) {
      console.error('Error translating IP to location:', error);
      throw error;
    }
  }; 


  export const GetVisitors = async (): Promise<VisitorTracker[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return GetVisitors(); // Retry fetching data
      }
      
      const url = `${BASE_URL}/visitorstats`;
      const response: AxiosResponse<VisitorTracker[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };