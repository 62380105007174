import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import FHPortalLayout from '../Layouts/FHPortalLayout';

// Define the type for customer data
type Customer = {
  id: number;
  name: string;
  lastVisit: string;
  totalVisits: number;
};

// Sample customer data (replace with data from your backend)
const sampleCustomers: Customer[] = [
  { id: 1, name: 'John Doe', lastVisit: '2024-10-25', totalVisits: 15 },
  { id: 2, name: 'Jane Smith', lastVisit: '2024-10-29', totalVisits: 22 },
  { id: 3, name: 'Alice Johnson', lastVisit: '2024-10-30', totalVisits: 8 },
];

const Retention = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  // Fetch customer data (replace with real fetch from your backend)
  useEffect(() => {
    // Simulate data fetch
    setCustomers(sampleCustomers);
  }, []);

  return (
    <FHPortalLayout>
      <Typography variant="h4" component="h1" gutterBottom>
        Customer Retention
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Customer Retention Table">
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell>Last Visit</TableCell>
              <TableCell>Total Visits</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{customer.lastVisit}</TableCell>
                <TableCell>{customer.totalVisits}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FHPortalLayout>
  );
};

export default Retention;