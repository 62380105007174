import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { BASE_URL } from "../../../domain/Types/Config";
import { Promotion } from "../../../domain/Types/Promotion";
import { PromotionMenuMapping } from "../../../domain/Types/PromotionMenuMapping";
import { MappedPromotions } from "../../../domain/Types/MappedPromotions";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const fetchPromotions = async (): Promise<Promotion[]> => {
  try {
        // Check for internet connectivity
        const online = await isOnline();
        if (!online) {
        console.log("No internet connection. Retrying...");
        return fetchPromotions(); // Retry fetching data
        }

        const url = `${BASE_URL}/Promotion`;
        const response: AxiosResponse<Promotion[]> = await axios.get(url);
        console.log(response.data);
        return response.data;
    } catch (error) 
    {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const SavePromotionTemplate = async (formData: FormData): Promise<Promotion> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return SavePromotionTemplate(formData); // Retry fetching data
    }

    const url = `${BASE_URL}/Promotion`;

    const response: AxiosResponse<Promotion> = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



export const SavePromotionTemplateold = async (promo: Promotion): Promise<Promotion> => {
    try {
          // Check for internet connectivity
          const online = await isOnline();
          if (!online) {
          console.log("No internet connection. Retrying...");
          return SavePromotionTemplateold(promo); // Retry fetching data
          }
  
          const url = `${BASE_URL}/Promotion`;
          const response: AxiosResponse<Promotion> = await axios.post(url, promo);
          console.log(response.data);
          return response.data;
      } catch (error) 
      {
          console.error('Error fetching data:', error);
          throw error;
      }
  };

  export const UpdatePromotion = async (promo: Promotion, imageFile?: File): Promise<Promotion> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UpdatePromotion(promo, imageFile); // Retry fetching data
      }
  
      const formData = new FormData();
      formData.append('name', promo.name);
      formData.append('discount_percentage', promo.discount_percentage?.toString() || '');
      formData.append('discount_amount', promo.discount_amount?.toString() || '');
      formData.append('start_date', promo.start_date);
      formData.append('end_date', promo.end_date);
      formData.append('description', promo.description || '');
  
      // Conditionally add the image file if it's provided
      if (imageFile) {
        formData.append('image', imageFile);
      }
  
      const url = `${BASE_URL}/Promotion/${promo.id}`;  // Assuming `promo.id` is the unique identifier for the promotion
  
      const response: AxiosResponse<Promotion> = await axios.patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure the header is set correctly for file uploads
        },
      });
  
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating promotion:', error);
      throw error;
    }
  };
  

  export const UpdatePromotionold = async (promo: Promotion): Promise<Promotion> => {
    try {
          // Check for internet connectivity
          const online = await isOnline();
          if (!online) {
          console.log("No internet connection. Retrying...");
          return UpdatePromotionold(promo); // Retry fetching data
          }
  
          const url = `${BASE_URL}/Updatepromotion`;
          const response: AxiosResponse<Promotion> = await axios.post(url, promo);
          console.log(response.data);
          return response.data;
      } catch (error) 
      {
          console.error('Error fetching data:', error);
          throw error;
      }
  };

  export const mapPromotionToMenu = async (promo: PromotionMenuMapping): Promise<[]> => {
    try {
          // Check for internet connectivity
          const online = await isOnline();
          if (!online) {
          console.log("No internet connection. Retrying...");
          return mapPromotionToMenu(promo); // Retry fetching data
          }
  
          const url = `${BASE_URL}/Menupromotion`;
          const response: AxiosResponse<[]> = await axios.post(url, promo);
          console.log(response.data);
          return response.data;
      } catch (error) 
      {
          console.error('Error fetching data:', error);
          throw error;
      }
  };

  export const getMappedPromotions = async (): Promise<MappedPromotions[]> => {
    try {
          // Check for internet connectivity
          const online = await isOnline();
          if (!online) {
          console.log("No internet connection. Retrying...");
          return getMappedPromotions(); // Retry fetching data
          }
  
          const url = `${BASE_URL}/Menupromotion`;
          const response: AxiosResponse<MappedPromotions[]> = await axios.get(url);
          console.log(response.data);
          return response.data;
      } catch (error) 
      {
          console.error('Error fetching data:', error);
          throw error;
      }
  };


  export const GetPromotion = async (promo: Promotion): Promise<Promotion> => {
    try {
          // Check for internet connectivity
          const online = await isOnline();
          if (!online) {
          console.log("No internet connection. Retrying...");
          return GetPromotion(promo); // Retry fetching data
          }
  
          const url = `${BASE_URL}/GetPromo`;
          const response: AxiosResponse<Promotion> = await axios.post(url, promo);
          return response.data;
      } catch (error) 
      {
          console.error('Error fetching data:', error);
          throw error;
      }
  };