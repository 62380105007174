import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Payment } from '../../domain/Types/Payment';
import { MakeRequest } from '../../infrastructure/api/Order/OrderAPI';
import { OrderRequest } from '../../domain/Types/OrderRequest';
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import { DeliveryDetails } from '../../domain/Types/DeliveryDetails';
import PaymentConfirmForm from './Components/Forms/PaymentConfirmForm';
import SpinnerModal from '../app/SpinnerModal';
import { ShoppingCartContext } from "../../utilities/Context/ShoppingCartContext";
import { database, ref, set } from '../../utilities/firebaseConfig';
import AppLayout from './Components/Layouts/App/AppLayout';
import { SaveCustomerJourney } from '../../infrastructure/api/CustomerJourney/CustomerJourneyAPI';

const PaymentConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const win = window.sessionStorage;
  const [loading, setLoading] = useState(false);
  const [connect, setConnect] = useState("");

  const { shoppingcart, selectedBusinessId, dispatch } = useContext(ShoppingCartContext);  // Get selectedBusinessId and dispatch from the ShoppingCartContext
  const { reset } = useForm();

  interface FormData {
    PaymentMethod: string;
    PaymentRef: string;
    VisaCardNumber?: string;
    VisaExpiryDate?: string;
    AirtelMoneyNumber?: string;
    MpambaNumber?: string;
    deliveryOption: 'pick-up' | 'delivery' | 'seat-down';
    DeliveryAddress?: string;
    NumberOfSeats?: string;
    FirstName: string;
    LastName: string;
    email: string;
    cellnumber: string;
    TotalPrice: number;
    VAT: number;
    TotalPriceIncludingVAT: number;
    DeliveryFee: number;
    cartitems: ShoppingCart[];
  }

  const SaveCustomerJourneyConsider = () => {
    
    try {
          const userIdString = win.getItem("userid");
          const userId = userIdString ? parseInt(userIdString) : 1;
        // Check if selectedBusinessId is null
        if (!selectedBusinessId) {
            console.warn('No business selected. Customer journey not saved.');
            return;
        }
        
        // Prepare the data to be sent
        const customerJourneyData = {
            user_id: userId, // Get the user ID from your state/context
            menu_id: 2,
            business_id: selectedBusinessId, // Assuming item has a businessId property
            stage: 'purchase', // The stage you want to log
            interaction_time: new Date().toISOString(), // Current timestamp
            metadata: JSON.stringify(shoppingcart), // Any additional data (optional)
        };

        SaveCustomerJourney(customerJourneyData);
        console.log('Customer journey saved successfully.');
    } catch (error) {
        console.error('Error saving customer journey:', error);
    }    

  };  

  const MakeanOrderRequest = async (ord: OrderRequest) => {
    try {
      setConnect("Please Wait, While we Send Your Order...");
      setLoading(true);

      const data = await MakeRequest(ord);
      const { order_number } = data;
      console.log(data);

        // Create a chat entry in the Firebase Realtime Database
        const chatRef = ref(database, order_number);
        await set(chatRef, {
            order_number: order_number,
            messages: [],
            createdAt: new Date().toISOString(),
        });      

      Swal.fire({
        title: 'SUCCESS',
        text: `Your Order has Placed Successfully: Order #${order_number}`,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      });

      // Empty the cart after successful order placement
      dispatch({ type: 'EMPTY_CART' });

      navigate("/MyOrders");

    } catch (error: any) {
      setLoading(false);
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 409) {
        // Handle duplicate transaction ID error
        Swal.fire({
          title: 'Duplicate Transaction Detected',
          text: 'A duplicate transaction ID has been detected. Please verify your payment and try again.',
          icon: 'error',
          showConfirmButton: true
        });
      } else {
        // Handle general errors
        Swal.fire({
          title: 'Unable to Send',
          text: 'Your Order could not be sent. Please try again.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 1500
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    const userIdString = win.getItem("userid");
    const userId = userIdString ? parseInt(userIdString) : 0;

    const delivery: DeliveryDetails = {
      id: 1,
      user_id: userId,
      delivery_address: "",
      latitude: 111111,
      longitude: 11111
    };

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Formats date as 'YYYY-MM-DD'

    const Pay: Payment = {
      id: 0,
      paymentoption_id: 1,
      amount: data.TotalPriceIncludingVAT,
      description: "PAYMENT FOR FOOD ORDER",
      payment_date: formattedDate,
      status: "",
      transaction_id: data.PaymentRef,
    };

    // Filter cart items to only include those for the selected businessId
    const filteredCartItems = data.cartitems.filter(item => item.businessId === selectedBusinessId);

    const cart_items = filteredCartItems.map(item => ({
      id: item.id,
      imagepath: item.imagepath,
      menu_id: item.menu_id,
      name: item.name,
      order_id: item.order_id,
      price: item.price,
      quantity: item.quantity,
      session_id: item.session_id,
      status: item.status,
      total: item.total,
      user_id: item.user_id,
      productattributevalues: [],
      businessId: item.businessId,
      businessName: item.businessName
    }));

    const NewOrder: OrderRequest = {
      customerid: userId,
      businessid: selectedBusinessId, // Use selectedBusinessId here
      cartitems: cart_items,
      totalamount: data.TotalPriceIncludingVAT,
      paymentid: 1,
      orderstatusid: 1,
      deliveryid: 1,
      deliveryDetails: delivery,
      PaymentDetails: Pay,
    };

    MakeanOrderRequest(NewOrder);

    reset();
  };

  return (
    <>
      <AppLayout>
        <PaymentConfirmForm onSubmit={onSubmit} />
      </ AppLayout>
      <SpinnerModal isLoading={loading} message={connect} />
    </>
  )
}

export default PaymentConfirmation;