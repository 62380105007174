import axios from "axios";

export const generateSessionID = () => {
    return Math.random().toString(36).substr(2, 9); // Simple session ID generator
};

export const getDeviceType = () => {
    return /Mobi|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop';
};

export const getBrowserInfo = () => {
    return navigator.userAgent;
};

export const getCookieConsent = () => {
    return !!document.cookie.match(/cookieConsent=true/);
};

export const getLocation = () => {
    return new Promise<{ latitude: number | null; longitude: number | null }>(async (resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                async (error) => {
                    console.error("Error fetching location", error);
                    // Attempt to get IP-based location
                    const ipLocation = await fetchIPLocation();
                    resolve(ipLocation);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
            // Attempt to get IP-based location
            const ipLocation = await fetchIPLocation();
            resolve(ipLocation);
        }
    });
};


const fetchIPLocation = async () => {
    try {
        const response = await axios.get("https://ipinfo.io/json?token=fddf595b0b958e"); // Replace with your token
        const [latitude, longitude] = response.data.loc.split(",");
        return { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
    } catch (error) {
        console.error("Failed to retrieve IP-based location", error);
        return { latitude: null, longitude: null }; // Return null if fetching fails
    }
};