import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import axios from 'axios';
import DriverLayout from './Components/Layouts/DriverLayout';
import { DetailedOrder } from '../../domain/Types/DetailedOrder';

const DriverOrderCompleted = () => {
  const [completedOrders, setCompletedOrders] = useState<DetailedOrder[]>([]);

  useEffect(() => {
    const fetchCompletedOrders = async () => {
      try {
        // Replace with actual API call to fetch completed orders for the driver
        const response = await axios.get('/api/driver/completed-orders');
        setCompletedOrders(response.data);
      } catch (error) {
        console.error('Error fetching completed orders:', error);
      }
    };

    fetchCompletedOrders();
  }, []);

  return (
    <DriverLayout>
      <Typography variant="h4" gutterBottom>
        Completed Orders
      </Typography>
      {completedOrders.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Delivery Address</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Completion Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.deliverydetail?.user_id || 'N/A'}</TableCell>
                  <TableCell>{order.deliverydetail?.delivery_address || 'N/A'}</TableCell>
                  <TableCell>MK{order.total_amount}</TableCell>
                  <TableCell>{/*new Date(order.updated_at).toLocaleString()*/}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No completed orders found.</Typography>
      )}
    </DriverLayout>
  );
};

export default DriverOrderCompleted;