import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react"
import FHPortalNavigation from "./FHPortalNavigation";
import Footer from "../../customer/Components/Layouts/Customer/Footer";
import Withauth from "../../../utilities/WithAuthentication/Withauth";

const FHPortalLayout = (props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined }) => {
  return (
    <>
      <FHPortalNavigation />
      <div className="container">      
      <div>{props.children}</div>
      </div>
      <br /><br />
      <Footer />    
    </>
  )
}

export default Withauth(FHPortalLayout);