import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShoppingBadge from '../CommmonComponents/ShoppingBadge';
import { ShoppingCart } from '../../../../domain/Types/ShoppingCart';
import { ShoppingCartContext } from '../../../../utilities/Context/ShoppingCartContext';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select';
import FoodHutBtn from '../CommmonComponents/FoodHutBtn';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import SigninModal from '../../../app/GeneralComponents/SigninModal';

export default function ShoppingCartFloat() {
  const navigate = useNavigate();
  const { shoppingcart, dispatch, getTotalItems, getTotalPrice, calculateVAT, calculateTotalPrice, selectedBusinessId } = useContext(ShoppingCartContext);
  const [businesses, setBusinesses] = useState<{ businessId: number, businessName: string }[]>([]);
  const win = window.sessionStorage;

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  const [isSigninModalOpen, setIsSigninModalOpen] = useState(false);
  const [actionOnSuccess, setActionOnSuccess] = useState<() => void>(() => () => {});
  // Function to open Signin Modal if user is not signed in
  const openSigninModal = (action: () => void) => {
    if (userId === 0) {
      // User is not signed in, so open modal
      setActionOnSuccess(() => action);
      setIsSigninModalOpen(true);
    } else {
      // User is already signed in, so execute the action directly
      action();
    }
  };

  const closeSigninModal = () => {
    setIsSigninModalOpen(false);
  };

  const handleSignInSuccess = () => {
    if (actionOnSuccess) {
      actionOnSuccess(); // Execute the action passed to SigninModal
    }
    closeSigninModal(); // Close the modal
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  // Populate businesses with unique business IDs and names
  useEffect(() => {
    const uniqueBusinesses = Array.from(
      new Set(shoppingcart.map(item => item.businessId))
    ).map(businessId => ({
      businessId,
      businessName: shoppingcart.find(item => item.businessId === businessId)?.businessName || `Business ${businessId}`
    }));
    setBusinesses(uniqueBusinesses);
  }, [shoppingcart]);

  useEffect(() => {
    // Set default business ID if no business is selected
    if (businesses.length > 0 && selectedBusinessId === null) {
      dispatch({ type: 'SET_SELECTED_BUSINESS', businessId: businesses[0].businessId });
    }
  }, [businesses, selectedBusinessId, dispatch]);

  const handleBusinessChange = (event: SelectChangeEvent<number>) => {
    const businessId = event.target.value as number;
    dispatch({ type: 'SET_SELECTED_BUSINESS', businessId });  // Update context with selected businessId
  };

  const handleReduceCart = (cart: ShoppingCart) => {
    dispatch({ type: 'REDUCE_CART', item: cart });
  };

  const handleIncrementCart = (cart: ShoppingCart) => {
    dispatch({ type: 'ADD_TO_CART', item: cart });
  };

  const handleRemoveFromCart = (cart: ShoppingCart) => {
    dispatch({ type: 'REMOVE_FROM_CART', item: cart });
  };

  const handleCheckout = () => {
    if (businesses.length > 0 && selectedBusinessId === null) {
      dispatch({ type: 'SET_SELECTED_BUSINESS', businessId: businesses[0].businessId });
    }
    navigate(`/Checkout`);
  };

  const currentBusinessId = typeof selectedBusinessId === 'number' ? selectedBusinessId : businesses[0]?.businessId;

  const totalPrice = getTotalPrice(shoppingcart.filter(item => item.businessId === currentBusinessId));
  const VAT = calculateVAT(totalPrice);
  const totalPriceIncludingVAT = calculateTotalPrice(totalPrice, VAT);

  const formattedTotalPrice = `MK ${totalPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  const formattedVAT = `MK ${VAT.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  const formattedTotalPriceIncludingVAT = `MK ${totalPriceIncludingVAT.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

  return (
    <>
    <Card sx={{ maxWidth: 345 }} variant="outlined">
      <CardHeader
        avatar={<ShoppingBadge />}
        action={<IconButton aria-label="settings"><MoreVertIcon /></IconButton>}
        title="Shopping Cart"
        subheader="Selected Menus"
      />

      {businesses.length > 1 && (
        <CardContent>
          <Select
            value={currentBusinessId}
            onChange={handleBusinessChange}
            label="Select Business"
            fullWidth
          >
            {businesses.map(business => (
              <MenuItem key={business.businessId} value={business.businessId}>
                {business.businessName}
              </MenuItem>
            ))}
          </Select>
        </CardContent>
      )}

      {shoppingcart
        .filter(cart => cart.businessId === currentBusinessId)
        .map((cart, index) => (
          <CardContent key={cart.id} sx={{ borderRadius: '12px' }}>
            <Stack direction="column" spacing={1}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  {cart.name}, Qty: {cart.quantity}
                </Typography>
                <Box>
                  <IconButton aria-label="Reduce" size="small" onClick={() => handleReduceCart(cart)}>
                    <RemoveOutlinedIcon />
                  </IconButton>
                  <IconButton aria-label="Add" size="small" onClick={() => handleIncrementCart(cart)}>
                    <AddOutlinedIcon />
                  </IconButton>
                  <IconButton aria-label="delete" size="small" onClick={() => handleRemoveFromCart(cart)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" color="text.secondary">
                  Price:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  MK {cart.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Typography>
              </Box>
            </Stack>

            {index < shoppingcart.filter(cart => cart.businessId === currentBusinessId).length - 1 && (
              <Divider sx={{ borderBottomWidth: 2, borderColor: 'gray', borderStyle: 'solid' }} />
            )}
          </CardContent>
        ))}

      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="text.secondary">
              <span className="font-bold">Total Quantity:</span>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {getTotalItems(shoppingcart.filter(item => item.businessId === currentBusinessId))}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="text.secondary">
              <span className="font-bold">SubTotal:</span>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formattedTotalPrice}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="text.secondary">
              <span className="font-bold">VAT:</span>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formattedVAT}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="text.secondary">
              <span className="font-bold">Total Cost:</span>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formattedTotalPriceIncludingVAT}
            </Typography>
          </Box>
        </Box>
      </CardContent>

      <CardActions disableSpacing>
        <FoodHutBtn
          aria-label="check out"
          size="small"
          variant="contained"
          endIcon={<SendIcon />}
          disabled={getTotalItems(shoppingcart.filter(item => item.businessId === currentBusinessId)) === 0}
          onClick={() => openSigninModal(() => handleNavigate('/Checkout'))}
        >
          Check Out
        </FoodHutBtn>
      </CardActions>
    </Card>
        <SigninModal open={isSigninModalOpen} handleClose={closeSigninModal} 
        onSignInFeedback={handleSignInSuccess}      
      />
      </>
  );
}