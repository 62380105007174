// AddToCartModal.tsx
import React, { useState } from 'react';
import { 
  Box, 
  Modal, 
  Button, 
  IconButton, 
  Rating, 
  Chip, 
  Typography 
} from '@mui/material';
import { 
  AddShoppingCart, 
  Close as CloseIcon, 
  Add as AddIcon, 
  Remove as RemoveIcon 
} from '@mui/icons-material';
import { Menu } from '../../../domain/Types/Menu';
import { ShoppingCart } from '../../../domain/Types/ShoppingCart';
import { ShoppingCartState } from '../../../utilities/Context/ShoppingCartContext';
import { SaveCustomerJourney } from '../../../infrastructure/api/CustomerJourney/CustomerJourneyAPI';

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  selectedItem: Menu | null;
  userId: number;
}

const AddToCartModal: React.FC<CustomModalProps> = ({
  open,
  handleClose,
  selectedItem,
  userId
}) => {
  const { dispatch } = ShoppingCartState();
  const [quantity, setQuantity] = useState<number>(1);

  const handleAddToCart = (menu: Menu) => {
    const totalAmount = menu.price * quantity;
    const cartItem: ShoppingCart = {
      id: menu.id,
      user_id: userId,
      session_id: menu.business_id,
      order_id: 1, // This could be dynamic, set to 1 for now
      menu_id: menu.id,
      imagepath: menu.image_path,
      status: menu.description,
      quantity,
      total: totalAmount,
      name: menu.name,
      price: menu.price,
      productattributevalues: [],
      businessId: menu.business_id,
      businessName: menu.business.name
    };

    // Dispatch to add the item to the shopping cart
    dispatch({ type: 'ADD_TO_CART', item: cartItem });

    try {
      // Prepare the data to send for customer journey logging
      const customerJourneyData = {
        user_id: userId, // Use the user ID passed as prop
        menu_id: menu.id,
        business_id: menu.business_id,
        stage: 'interest', // The stage you want to log, can be dynamic
        interaction_time: new Date().toISOString(),
        metadata: JSON.stringify(menu), // Optional metadata
      };

      // Save customer journey data
      //SaveCustomerJourney(customerJourneyData);
      console.log('Customer journey saved successfully.');
    } catch (error) {
      console.error('Error saving customer journey:', error);
    }

    // Close the modal after adding the item to the cart
    handleClose();
  };

  const incrementQuantity = () => setQuantity((prev) => prev + 1);
  const decrementQuantity = () => quantity > 1 && setQuantity((prev) => prev - 1);

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: 40,
          left: 70,
          width: '90vw',
          height: '90vh',
          maxWidth: '90%',
          maxHeight: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          borderRadius: '16px'
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>

        {selectedItem && (
          <>
            <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
              <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
                {/* Image with Name Overlay at Bottom-Left */}
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '300px',
                    overflow: 'hidden',
                    borderRadius: '8px'
                  }}
                >
                  <img
                    src={`https://app.foodhut.mw/Admin/public/images/${selectedItem.image_path}`}
                    alt={selectedItem.name}
                    className="w-full h-full object-cover"
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: '15%',
                      bgcolor: 'rgba(0, 0, 0, 0.3)', // Slightly dark overlay
                      display: 'flex',
                      alignItems: 'center',
                      pl: 2,
                      pb: 1,
                    }}
                  >
                    <Typography 
                      variant="h5" 
                      fontWeight="bold" 
                      color="white"
                    >
                      {selectedItem.name}
                    </Typography>
                  </Box>
                </Box>

                <p className="text-xl font-bold border-b mt-4">Details</p>
                <div>
                  <p>
                    <span className="text-lg font-medium">Description: </span>
                    {selectedItem.description}
                  </p>
                  <p>
                    <span className="text-lg font-medium">Rating: </span>
                    <Rating value={selectedItem.average_rating} precision={0.5} readOnly />
                  </p>
                  <p>
                    <span className="text-lg font-medium">Price: </span>
                    MK{' '}
                    {selectedItem.price.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </p>
                </div>

                <p className="text-xl border-t font-bold">Ingredients</p>
                <p>{selectedItem.description}</p>

                <p className="text-xl border-t mt-2 font-bold">FoodStore Details</p>
                <p className="text-lg">{selectedItem.business.name}</p>
                <p>{selectedItem.business.description}</p>
              </div>

              <div className="px-4 rounded-lg border">
                <p className="text-xl font-medium">Item Summary</p>
                <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 sm:px-6">
                  <div key={selectedItem.id} className="flex flex-col sm:flex-row">
                    <div className="flex w-full flex-col px-4">
                      <div className="flex items-center justify-between mt-2">
                        <Chip
                          label={selectedItem.status === 'available' ? 'Available' : 'Not Available'}
                          sx={{
                            backgroundColor: selectedItem.status === 'available' ? 'green' : 'red',
                            color: 'white',
                            fontWeight: 'bold'
                          }}
                          size="small"
                        />
                        <span className="font-semibold">{selectedItem.name}</span>
                      </div>

                      <div className="flex items-center justify-between mt-4">
                        <div className="flex items-center">
                          <IconButton onClick={decrementQuantity} aria-label="decrease quantity">
                            <RemoveIcon />
                          </IconButton>
                          <span className="text-lg font-bold mx-2">{quantity}</span>
                          <IconButton onClick={incrementQuantity} aria-label="increase quantity">
                            <AddIcon />
                          </IconButton>
                        </div>
                        <span className="text-lg font-bold">
                          Total: MK{' '}
                          {(selectedItem.price * quantity).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  sx={{ mt: 4, mb: 8 }}
                  onClick={() => handleAddToCart(selectedItem)}
                >
                  Add to Cart
                </Button>
              </div>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AddToCartModal;