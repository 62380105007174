import { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import { FetchMenuCategories } from '../../../../infrastructure/api/Menus/MenuAPI'; 
import { MenuCategory } from '../../../../domain/Types/MenuCategory'; 

type CategoryWithIcon = MenuCategory & {
  label: string;
  icon: string;
};

// Icon mapping
const categoryIcons: { [key: string]: string } = {
  Wraps: '🌯',
  Burgers: '🍔',
  Meals: '🍽️',
  Sides: '🍟',
  Sandwiches: '🥪',
  Drinks: '🥤',
  Breakfast: '🍳',
  Salads: '🥗',
  Starters: '🍢',
  Pasta: '🍝',
  'Fish & Chips': '🍟',
  'Signature Dishes': '🌟',
  'OFF-THE-GRILL': '🔥',
  DESSERTS: '🍰',
  'SOMETHING LITE': '🥗',
  TACOS: '🌮',
  QUESADILLAS: '🌮',
  PIZZAS: '🍕',
  BURRITOS: '🌯',
  SAMOSA: '🥟',
};

const CategorySection = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<CategoryWithIcon[]>([]);
  const [loading, setLoading] = useState(true);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data: MenuCategory[] = await FetchMenuCategories();
        const mappedCategories = data.map(category => ({
          ...category,
          label: category.name || 'Unknown',
          icon: categoryIcons[category.name || ''] || '🍽️',
        }));
        setCategories(mappedCategories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Dynamically set the number of displayed categories based on screen size
  const getDisplayedCategories = () => {
    if (isMobile) return categories.slice(0, 6);  // Show 8 on mobile
    if (isTablet) return categories.slice(0, 9); // Show 10 on tablet
    if (isDesktop) return categories.slice(0, 12); // Show 12 on desktop
    return categories;
  };

  const displayedCategories = getDisplayedCategories();

  const handleCategoryClick = (categoryId: number | null, categoryName: string) => {
    if (categoryId !== null) {
      // Pass both categoryId and categoryName via state
      navigate("/DishesPage", { state: { Category_id: categoryId, name: categoryName } });
    } else {
      console.error("Category ID is null");
    }
  };
  

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px', margin: '10px 0' }}>
      {loading ? (
        Array.from(new Array(isMobile ? 8 : isTablet ? 10 : 12)).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            sx={{
              width: '80px',
              height: '100px',
              margin: '5px',
              borderRadius: '10px',
            }}
          />
        ))
      ) : (
        displayedCategories.map((category, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              border: '1px solid #eee',
              borderRadius: '10px',
              width: '80px',
              height: '100px',
              margin: '5px',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={() => handleCategoryClick(category.id, category.label)} 
          >
            <Typography variant="h4" sx={{ marginBottom: 1 }}>
              {category.icon}
            </Typography>
            <Typography variant="body2">{category.label}</Typography>
          </Box>
        ))
      )}
    </Box>
  );
};

export default CategorySection;