import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FetchMenuByCategory } from '../../infrastructure/api/Menus/MenuAPI';
import MenuCard from './Components/OtherComponents/MenuCard';
import { Menu } from '../../domain/Types/Menu';
import AppLayout from './Components/Layouts/App/AppLayout';
import AddToCartModal from '../app/GeneralComponents/AddToCartModal';
import { MenuSearch } from '../../domain/Types/MenuSearch';
import { Grid, Typography, Button } from '@mui/material';  // Import MUI components

const DishesPage = () => {
  const location = useLocation();
  const categoryid = location.state?.Category_id; // Access the passed category id
  const categoryName = location.state?.name; // Access the passed category name
  const [menus, setMenus] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false); 
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null); 
  const win = window.sessionStorage;
  const navigate = useNavigate();  

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;  

  const handleOpenModal = (item: Menu) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    const search: MenuSearch = {
      Categoryid: categoryid,
      Latitude: 0,
      Longitude: 0
    };

    const fetchMenus = async () => {
      try {
        if (categoryid) {
          const data = await FetchMenuByCategory(search); // Fetch dishes based on categoryId
          setMenus(data);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred while fetching dishes.");
      } finally {
        setLoading(false);
      }
    };

    fetchMenus();
  }, [categoryid]);

  if (loading) {
    return <AppLayout>Loading {categoryName}...</AppLayout>;
  }

  if (error) {
    return (
      <AppLayout>
        <Typography variant="h5">{categoryName} not Available - Come back soon...</Typography>
        <Button variant="contained" component={Link} to="/">Go back to Home</Button>
      </AppLayout>
    );
  }

  if (menus.length === 0) {
    return (
      <AppLayout>
        <Typography variant="h5">{categoryName} not available for this category.</Typography>
        <Button variant="contained" component={Link} to="/">Go back to Home</Button>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <Typography variant="h4" gutterBottom>{categoryName} Within your area</Typography>
      <Grid container spacing={2}>
        {menus.map(menu => (
          <Grid item xs={12} sm={6} md={3} key={menu.id}> {/* Grid with 4 items per row */}
            <MenuCard
              FoodMenu={menu}
              onViewOption={() => navigate("/MenuOptions")}
              onSelectmenu={() => handleOpenModal(menu)}
            />
          </Grid>
        ))}
      </Grid>
      {selectedItem && (
        <AddToCartModal 
          open={openModal} 
          handleClose={handleCloseModal}
          selectedItem={selectedItem} 
          userId={userId}
        />  
      )}      
    </AppLayout>
  );
};

export default DishesPage;