import { Order } from "./Order";

// Enum for status to ensure better type safety and maintainability
export enum OrderStatus {
    ASSIGNED = 'assigned',
    IN_TRANSIT = 'in transit',
    DELIVERED = 'delivered',
    CANCELLED = 'cancelled',
  }
  
  export type ReceivedOrderDriverAssignment = {
    id: number; // Unique ID for the assignment
    order_id: number; // References the ID of the associated order
    user_id: number; // References the ID of the assigned driver
    assignment_time: string; // Timestamp for assignment in format 'YYYY-MM-DD HH:mm:ss'
    pickup_time?: string | null; // Nullable timestamp for pickup time (ISO 8601 or null)
    delivery_time?: string | null; // Nullable timestamp for delivery time (ISO 8601 or null)
    status: OrderStatus; // Enum type for order status ('assigned', 'in transit', 'delivered', 'cancelled')
    current_location?: string | null; // Nullable field for the current location (e.g., "123 Main St" or latitude/longitude)
    notes?: string | null; // Nullable text field for additional notes
    created_at: string; // ISO 8601 format for creation timestamp
    updated_at: string; // ISO 8601 format for update timestamp
    order: Order// Nested order object, based on the example data
  };
  