import { useParams, useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBadge from '../../CommmonComponents/ShoppingBadge';
import FoodStoreSearchForm from '../../Forms/FoodStoreSearchForm';
import { useState, useEffect } from 'react';
import { Category } from '../../../../../domain/Types/Category';
import { Button, Stack, useMediaQuery, useTheme, Skeleton, AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box, Typography, Autocomplete } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FoodhutChip from '../../OtherComponents/FoodhutChip';
import { fetchCategoriesData } from '../../../../../infrastructure/api/Category/CategoryAPI';
import { GeoSearch } from '../../../../../domain/Types/GeoSearch';
import { fetchProxBusiness } from '../../../../../infrastructure/api/BusinessAddress/BusinessAddressAPI';
import { AddressBizDetailed } from '../../../../../domain/Types/AddressBizDetailed';
import { fetchAllAddressedBusiness } from '../../../../../infrastructure/api/Business/BusinessAPI';
import SigninModal from '../../../../app/GeneralComponents/SigninModal';
import BusinessSigninModal from '../../../../app/GeneralComponents/BusinessSigninModal';
import styled from 'styled-components';
import Swal from "sweetalert2";
import MobileSearchBar from '../../CommmonComponents/MobileSearchBar';
import LogoutIcon from '@mui/icons-material/Logout';
import DriverSigninModal from '../../../../app/GeneralComponents/DriverSigninModal';

const customColor = '#e88824';

const StyledButton = styled.button`
  background-color: black;
  color: white;
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${customColor};
  }
`;

const AppNavigation = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [latLng, setLatLng] = useState<{ lat: number | null; lng: number | null }>({ lat: null, lng: null });
  const [radius, setRadius] = useState<number>(0);
  const [searchTriggered, setSearchTriggered] = useState<boolean>(false);
  const [showAllChips, setShowAllChips] = useState(false);
  const [category, setCategory] = useState<Category[] | null>(null);
  const displayedCategories = showAllChips ? category : category?.slice(0, 15);
  const [loading, setLoading] = useState<boolean>(true);
  const { Category: initialFoodstorecat } = useParams<{ Category: string }>();
  const [foodstorecat, setFoodstorecat] = useState<string | undefined>(initialFoodstorecat);
  const [businessAdd, setBusinessAdd] = useState<AddressBizDetailed[] | null>(null);
  const [isSigninModalOpen, setIsSigninModalOpen] = useState(false);
  const [isBusinessSigninModalOpen, setIsBusinessSigninModalOpen] = useState(false);
  const [isDriverSigninModalOpen, setIsDriverSigninModalOpen] = useState(false);  
  const [actionOnSuccess, setActionOnSuccess] = useState<() => void>(() => () => {});
  const [BusinessactionOnSuccess, setBusinessActionOnSuccess] = useState<() => void>(() => () => {});
  const [DriveractionOnSuccess, setDriverActionOnSuccess] = useState<() => void>(() => () => {});
  const win = window.sessionStorage;
  // Inside your component
  const isMobile = useMediaQuery('(max-width:600px)'); // 600px for mobile screen
  const [searchText, setSearchText] = useState('');
  const [selectedOption, setSelectedOption] = useState<SearchOption | null>(null);
  type SearchOption = { label: string; id: number };

 

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleFoodhutCategory = (categoryName: string) => {
    setLoading(true);
    setSearchTriggered(true);
    setFoodstorecat(categoryName);
  };

  const searchOptions = [
    { label: 'Pizza', id: 1 },
    { label: 'Burger', id: 2 },
    { label: 'Sushi', id: 3 },
    { label: 'Pasta', id: 4 },
    { label: 'Salad', id: 5 },
  ];

  const handleSearch = () => {
    if (selectedOption) {
      console.log('Searching for:', selectedOption.label);
    } else {
      console.log('Searching for:', searchText);
    }
  };

  const handleCategoryClick = (categoryName: string, categoryId: number) => {
    navigate('/FoodCategory', { state: { categoryName, categoryId } });
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const fetchBusinessesByLocation = async (latitude: number, longitude: number, radius: number) => {
    const search: GeoSearch = {
      DeliveryAddress: "",
      Category: 0,
      Latitude: latitude,
      Longitude: longitude,
      StartingPrice: 0,
      EndingPrice: 0,
      Radius: radius
    };

    try {
      const data = await fetchProxBusiness(search);
      setBusinessAdd(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllBusinesses = async () => {
    try {
      const data = await fetchAllAddressedBusiness();
      setBusinessAdd(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await fetchCategoriesData();
        setCategory(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();

    if (navigator.geolocation && radius > 0) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLng({ lat: latitude, lng: longitude });
          fetchBusinessesByLocation(latitude, longitude, radius);
        },
        (error) => {
          console.error("Error obtaining location:", error);
          fetchAllBusinesses();  // Fallback to full search if geolocation fails
        }
      );
    } else {
      fetchAllBusinesses();  // Fallback to full search if geolocation is not supported or radius is 0
      if (radius > 0) console.log("Geolocation is not supported by this browser.");
    }
  }, [radius, foodstorecat]);

  useEffect(() => {
    if (searchTriggered) {
      if (latLng.lat !== null && latLng.lng !== null && radius > 0) {
        fetchBusinessesByLocation(latLng.lat, latLng.lng, radius);
      } else {
        fetchAllBusinesses();
      }
    }
  }, [foodstorecat, searchTriggered]);



  // Function to open Signin Modal if user is not signed in
  const openSigninModal = (action: () => void) => {
    if (userId === 0) {
      // User is not signed in, so open modal
      setActionOnSuccess(() => action);
      setIsSigninModalOpen(true);
    } else {
      // User is already signed in, so execute the action directly
      action();
    }
  };

  // Function to open BusinessSignin Modal if user is not signed in
  const openBusinessSigninModal = (action: () => void) => {
    if (userId === 0) {
      // User is not signed in, so open modal
      setBusinessActionOnSuccess(() => action);
      setIsBusinessSigninModalOpen(true);
    } else {
      // User is already signed in, so execute the action directly
      action();
    }
  };  

  // Function to open BusinessSignin Modal if user is not signed in
  const openDriverSigninModal = (action: () => void) => {
    if (userId === 0) {
      // User is not signed in, so open modal
      setDriverActionOnSuccess(() => action);
      setIsDriverSigninModalOpen(true);
    } else {
      // User is already signed in, so execute the action directly
      action();
    }
  };    

  const closeSigninModal = () => {
    setIsSigninModalOpen(false);
  };

  const closeBusinessSigninModal = () => {
    setIsBusinessSigninModalOpen(false);
  };

  const closeDriverSigninModal = () => {
    setIsDriverSigninModalOpen(false);
  };  

  // Function to handle navigation or opening sign-in modal
  /*const handleAccountClick = () => {
    if (userId === 0) {
      // If userId is not set, show SignInModal
      openSigninModal();
    } else {
      // If userId is set, navigate to /CustomerAccount
      handleNavigate('/CustomerAccount');
    }
  };*/

  /*const handleSignInFeedback = (success: any) => {
    if (success) {
      // Do something unique in this component on sign-in success
      navigate('/CustomerAccount');
    } else {
      // Handle failure
      Swal.fire('Sign-in Failed', 'Please try again.', 'error');
    }
  };*/

  const handleSignInSuccess = () => {
    if (actionOnSuccess) {
      actionOnSuccess(); // Execute the action passed to SigninModal
    }
    closeSigninModal(); // Close the modal
  };

  const handleBusinessSignInSuccess = () => {
    if (BusinessactionOnSuccess) {
      BusinessactionOnSuccess(); // Execute the action passed to SigninModal
    }
    closeBusinessSigninModal(); // Close the modal
  };

  const handleDriverSignInSuccess = () => {
    if (DriveractionOnSuccess) {
      DriveractionOnSuccess(); // Execute the action passed to SigninModal
    }
    closeDriverSigninModal(); // Close the modal
  };  

  const handleNavigate = (path: string) => {
    navigate(path);
    toggleDrawer(false); // Close the drawer after navigation
  };

  const handleURLNavigate = (path: string) => {
    if (userId === 0) {
      openSigninModal(() => handleNavigate(path)); // Pass navigation as an action
    } else {
      handleNavigate(path);
    }
  }

  const handleAccountClick = () => {
    if (userId === 0) {
      openSigninModal(() => handleNavigate('/CustomerAccount')); // Pass navigation as an action
    } else {
      handleNavigate('/CustomerAccount');
    }
  };

  const handleAnotherAction = () => {
    // Define another action, e.g., navigate to a different page or execute some logic
  };  

 

  return (
    <>
    <div className="container">
      {/* MUI AppBar with Drawer */}
      <AppBar
        position="relative" // Make it non-sticky
        sx={{ backgroundColor: '#FFFFFF', boxShadow: 'none' }} // Remove shadow
      >
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ color: {customColor}, mr: 2 }} // Set color to orange
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>FoodHut</Link>
          </Typography>
          {!isMobile && (<span className="mx-2 w-full"><MobileSearchBar /> </span>)}       
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body2" component="div" sx={{ flexGrow: 1, marginRight: "20px" }}>
            <span className="font-bold" style={{ textDecoration: 'none', color: 'black' }}>Welcome: </span>
            <span style={{ textDecoration: 'none', color: 'black' }}>
              {win.getItem("fname") && win.getItem("lname") 
                ? `${win.getItem("fname")} ${win.getItem("lname")}` 
                : "Guest"}
            </span>
          </Typography>
          {!isMobile && (
            <Box display="flex" alignItems="center">
  <Typography
    variant="body2"
    component="div"
    sx={{ marginRight: 2 }}
    style={{ textDecoration: 'none', color: 'black' }}
  >
    |
  </Typography>

  <Typography
    variant="body2"
    component="div"
    sx={{ marginRight: 2 }}
  >
    <Link
      to="#"
      style={{ textDecoration: 'none', color: 'black' }}
      onClick={() => openBusinessSigninModal(() => handleNavigate('/Dashboard'))}
    >
      Business
    </Link>
    <span style={{ margin: '0 10px' }}>|</span> {/* Add space between links */}
    <Link
      to="#"
      style={{ textDecoration: 'none', color: 'black' }}
      onClick={() => openDriverSigninModal(() => handleNavigate('/Driverhome'))}
    >
      Driver
    </Link>
  </Typography>
</Box>

          )}
      
            {/*<Typography variant="body2" component="div" sx={{ flexGrow: 1, marginRight: "10px"}}>
              <Link to="/Signin" style={{ textDecoration: 'none', color: 'black' }}>Business Owner</Link>
            </Typography>*/}
            <ShoppingBadge />
          </Box>
        </Toolbar>
      </AppBar>

      {/* MUI Drawer */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {isMobile ? (
              <>
                {/* Mobile-specific menu */}
                <ListItem button onClick={() => openSigninModal(() => handleNavigate('/'))}>
                  <ListItemText primary="Signin" />
                </ListItem>
                <ListItem button onClick={() => openBusinessSigninModal(() => handleNavigate('/Dashboard'))}>
                  <ListItemText primary="Business Owner" />
                </ListItem>  
                <ListItem button onClick={() => openDriverSigninModal(() => handleNavigate('/Driverhome'))}>
                  <ListItemText primary="Driver" />
                </ListItem>                               
                <ListItem button onClick={() => handleNavigate('/Register')}>
                  <ListItemText primary="Register" />
                </ListItem>
                <ListItem button onClick={() => handleURLNavigate("/Favourites")}>
                  <ListItemText primary="Favourites" />
                </ListItem>
                <ListItem button onClick={() => handleURLNavigate("/Home")}>
                  <StyledButton>FoodStores near you</StyledButton>
                </ListItem>
                <ListItem button onClick={() => handleURLNavigate("/MyOrders")}>
                  <ListItemText primary="My Orders" />
                </ListItem>
                <ListItem button onClick={handleAccountClick}>
                  <ListItemText primary="My Account" />
                </ListItem>
                      {/* Logout Option */}
                <ListItem 
                  button 
                  onClick={() => {
                    win.clear(); // Clear session
                    navigate('/'); // Redirect to sign-in
                  }}
                >
                  <LogoutIcon sx={{ marginRight: 1 }} />
                  <ListItemText primary="Logout" />
                </ListItem>

              </>
            ) : (
              <>
                {/* Non-mobile menu */}
                <ListItem button onClick={() => openSigninModal(() => handleNavigate('/'))}>
                  <ListItemText primary="Signin" />
                </ListItem>                
                <ListItem button onClick={() => handleNavigate('/Register')}>
                  <ListItemText primary="Register" />
                </ListItem>
                <ListItem button onClick={() => handleURLNavigate("/Favourites")}>
                  <ListItemText primary="Favourites" />
                </ListItem>
                <ListItem button onClick={() => handleURLNavigate("/Home")}>
                  <StyledButton>FoodStores near you</StyledButton>
                </ListItem>
                <ListItem button onClick={() => handleURLNavigate("/MyOrders")}>
                  <ListItemText primary="My Orders" />
                </ListItem>
                <ListItem button onClick={handleAccountClick}>
                  <ListItemText primary="My Account" />
                </ListItem>
                <ListItem 
                  button 
                  onClick={() => {
                    win.clear(); // Clear session
                    navigate('/'); // Redirect to sign-in
                  }}
                >
                  <LogoutIcon sx={{ marginRight: 1 }} />
                  <ListItemText primary="Logout" />
                </ListItem>                
              </>
            )}
          </List>
        </Box>
      </Drawer>


      <div className="container">
        {!isMobile && (
          <FoodStoreSearchForm
            onSubmit={(data) => {
              setRadius(data.radius);
              setSearchTriggered(true);
            }}
          />
        )}

    {isMobile && (
      <div className="mobile-search-bar" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <MobileSearchBar />
        {/*<Autocomplete
          freeSolo
          options={searchOptions}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.label
          }
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setSelectedOption(null); // If free input, reset option
              setSearchText(newValue);
            } else {
              setSelectedOption(newValue);
              setSearchText(newValue?.label || '');
            }
          }}
          inputValue={searchText}
          onInputChange={(event, newInputValue) => setSearchText(newInputValue)}
          renderInput={(params) => (
            <TextField 
              {...params} 
              variant="outlined" 
              size="small" 
              placeholder="Search" 
              fullWidth  // Ensures the search input field takes full width
            />
          )}
          sx={{ flexGrow: 1 }} // Allows Autocomplete to expand
        />
        <IconButton onClick={handleSearch}>
          <SearchIcon />
        </IconButton>*/}
      </div>
    )}


        {!isMobile && (
              <Stack
                direction="row"
                spacing={isXs ? 0.25 : isSm ? 0.5 : 1}
                className={`flex-wrap gap-2 ${isXs || isSm ? 'justify-center' : 'justify-start'}`}
              >
                {loading
                  ? Array.from(new Array(18)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        animation="wave"
                        width={120}
                        height={30}
                        sx={{ borderRadius: "12px 12px 12px 12px" }}
                      />
                    ))
                  : displayedCategories?.map((cat: Category) => (
                      <FoodhutChip
                        Category={cat.name}
                        key={cat.id}
                        onSelectCategory={() => handleCategoryClick(cat.name, cat.id)}
                        imageUrl={cat.image_path}
                      />
                    ))}
                {category && category.length > 5 && (
                  <Button onClick={() => setShowAllChips(!showAllChips)}>
                    {showAllChips ? 'Show Less' : 'Show More'}
                  </Button>
                )}
              </Stack>
            )}
      </div>
    </div>
    <SigninModal open={isSigninModalOpen} handleClose={closeSigninModal} 
      onSignInFeedback={handleSignInSuccess}      
    />
    <BusinessSigninModal open={isBusinessSigninModalOpen} handleClose={closeBusinessSigninModal} 
      onSignInFeedback={handleBusinessSignInSuccess}      
    />

    <DriverSigninModal open={isDriverSigninModalOpen} handleClose={closeDriverSigninModal} 
      onSignInFeedback={handleDriverSignInSuccess}      
    />    
    </>
  );
};

export default AppNavigation;