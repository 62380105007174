import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import { TableContainer, Table, TableHead, Paper, TableCell, TableRow, TableBody, Button } from '@mui/material';
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import ChatBox from '../app/GeneralComponents/ChatBox';
import AppLayout from './Components/Layouts/App/AppLayout';

const TrackOrder = () => {
  const location = useLocation();
  const { order } = location.state || {};

  const [Cartitems, setCartitems] = useState<ShoppingCart[] | null>(null);

  useEffect(() => {
    if (order) {
      setCartitems(order.cart);
    }
  }, [order]);

  if (!order) {
    return (
      <CustomerLayout>
        <div>No order details available.</div>
      </CustomerLayout>
    );
  }

  const handleCall = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <AppLayout>
      <p>Order #: <span className='font-bold'>{order.order_number}</span></p>
      <p>Status: <span className='font-bold'>{order.orderstatus?.status}</span></p>
      <p className="font-bold">Ordered Items</p>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Cartitems && Cartitems.map((cartitem) => (
                <TableRow
                  key={cartitem.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{cartitem.name}</TableCell>
                  <TableCell align="right">{cartitem.status}</TableCell>
                  <TableCell align="right">{cartitem.total}</TableCell>
                  <TableCell align="right">{cartitem.price}</TableCell>
                  <TableCell align="right">
                    <Link 
                      to={`/MenuFeedback`}
                      state={{ 
                        menu_id: cartitem.menu_id,
                        order_number: order.order_number,
                        item_name: cartitem.name,
                      }}
                    >
                      <Button variant="contained" color="primary">Review Item</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br />
      <div className="w-full">Total Amount Paid: <span className='font-bold'>MK {order.total_amount}</span></div>
      <br />
      <Link to="/Home">Continue Shopping</Link>

      <ChatBox orderNumber={order.order_number} />
    </AppLayout>
  );
};

export default TrackOrder;