import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import AppLayout from '../customer/Components/Layouts/App/AppLayout';
import { TextField, Button, Grid, Typography, Box, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import { UserForgotPassword } from '../../infrastructure/api/User/UserAPI';
import { PasswordReset } from '../../domain/Types/PasswordReset';

// Define the form input types
interface ForgotPasswordForm {
  email: string;
}

const ForgotPassword = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<ForgotPasswordForm>();
  const [isSuccess, setIsSuccess] = useState(false); // State to manage success message visibility
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to manage loading spinner visibility

  // API call on form submission
  const onSubmit: SubmitHandler<ForgotPasswordForm> = async (data) => {
    setIsLoading(true); // Set loading state to true
    try {
      const pwdreset: PasswordReset = {
        email: data.email,
        password: '',
        password_confirmation: '',
        token: '',
      };
      // Call the ForgotPassword API with the email
      await UserForgotPassword(pwdreset);

      Swal.fire({
        title: 'Success',
        text: 'A password reset link has been sent to your email.',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });

      setIsSuccess(true); // Set success state to true
      setMessage(''); // Clear any existing message
    } catch (error: any) {
      console.error('Error resetting password:', error);
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.message || 'Something went wrong. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      setMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false); // Set loading state to false after API call completes
    }
  };

  return (
    <AppLayout>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {/* Conditional Rendering for Success Message */}
        {isSuccess ? (
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ padding: '2rem' }}>
              <Typography variant="h4" gutterBottom>
                Reset Link Sent
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                A password reset link has been successfully sent to your email address. Please check your inbox.
              </Typography>
              <Button color="primary" href="/login" style={{ marginTop: '1rem' }}>
                Back to Login
              </Button>
            </Box>
          </Grid>
        ) : (
          // Left Section: Illustration
          <>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src="/path/to/your/image.jpg" alt="Password Reset Illustration" style={{ width: '60%' }} />
              </Box>
            </Grid>

            {/* Right Section: Form */}
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ padding: '2rem' }}>
                <Typography variant="h4" gutterBottom>
                  Forgot Password
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Enter your email and we'll send you a link to reset your password.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '70%' }}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Email is required',
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: 'Invalid email address',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Enter your email address"
                        variant="outlined"
                        type="email"
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                        style={{ marginBottom: '1rem' }}
                      />
                    )}
                  />
                  {errors.email && (
                    <Typography color="error">
                      {errors.email.message}
                    </Typography>
                  )}
                  <Button 
                    type="submit" 
                    variant="contained" 
                    color="success" 
                    fullWidth 
                    style={{ marginTop: '1rem' }}
                    disabled={isLoading} // Disable the button while loading
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                  </Button>
                </form>
                {message && (
                  <Typography variant="body1" color="textSecondary" style={{ marginTop: '1rem' }}>
                    {message}
                  </Typography>
                )}
                <Button color="secondary" href="/login" style={{ marginTop: '1rem' }}>
                  Back to Login
                </Button>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </AppLayout>
  );
};

export default ForgotPassword;