import React from 'react'
import AdminLayout from '../../customer/Components/Layouts/Admin/AdminLayout'

const AdminSettings = () => {
  return (
    <AdminLayout>
        AdminSettings
    </AdminLayout>
  )
}

export default AdminSettings