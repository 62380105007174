import React from 'react'
import DriverLayout from './Components/Layouts/DriverLayout'

const LiveTracking = () => {
  return (
    <DriverLayout>
      LiveTracking
    </DriverLayout>
  )
}

export default LiveTracking