import React, {ComponentType } from 'react';
import Signin from '../../Presentation/app/Signin';
import LandingScreen from '../../Presentation/app/LandingScreen';



const Withauth = (Component: ComponentType<any>) => {
  return class extends React.Component {
    render() {
      const win = window.sessionStorage;

      const isAuthenticated = win.getItem('Token');
      if (isAuthenticated !== null) {
          return <Component {...this.props} />
      }
      else{
        //return <Signin />;
        return <LandingScreen />
      }
    }
  };
};

export default Withauth;
