import React from 'react';
import { useLocation } from 'react-router-dom';
import AppLayout from './Components/Layouts/App/AppLayout';

const SearchResults = () => {
    const location = useLocation();
    const { results = [], query = '' } = location.state || {};

    return (
        <AppLayout>
            <div className="mt-2">
                <h1>Search Results for: {query}</h1>
                {results.length > 0 ? (
                    results.map((item: any) => <div key={item.id}>{item.name}</div>)
                ) : (
                    <p>No results found.</p>
                )}
            </div>
        </AppLayout>
    );
}

export default SearchResults;
