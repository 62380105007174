import React from 'react'
import DriverLayout from './Components/Layouts/DriverLayout'

const ProofOfDelivery = () => {
  return (
    <DriverLayout>
      ProofOfDelivery
    </DriverLayout>
  )
}

export default ProofOfDelivery