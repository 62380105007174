import React, { useState, useEffect } from 'react';
import {
  Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TextField, Switch, FormControlLabel, Typography, Modal, MenuItem,
  CircularProgress
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import AdminLayout from '../../customer/Components/Layouts/Admin/AdminLayout';
import { Menu } from '../../../domain/Types/Menu';
import { MenuCategory } from '../../../domain/Types/MenuCategory';
import { FetchMenuCategories, fetchMenuData, SaveMenu } from '../../../infrastructure/api/Menus/MenuAPI';
import axios from 'axios';

// Define a schema using Zod for form validation, excluding slug
const menuSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().min(1, 'Description is required'),
  price: z.number().positive('Price must be greater than zero'),
  menucategory_id: z.number().int().positive('Menu category is required'),
  business_id: z.number().int().positive('Business ID is required'),
  status: z.enum(['available', 'unavailable']),
  image_path: z.string().min(1, 'Image is required'), // Mandatory image upload
});

type MenuFormData = z.infer<typeof menuSchema>;

const BusinessMenus: React.FC = () => {
  const { control, handleSubmit, reset, setValue } = useForm<MenuFormData>({
    //resolver: zodResolver(menuSchema),
    defaultValues: {
      name: '',
      description: '',
      price: 0,
      menucategory_id: 0,
      business_id: parseInt(window.sessionStorage.getItem('Businessid') || '0', 10),
      status: 'available',
      image_path: '',
    },
  });

  const [menus, setMenus] = useState<Menu[]>([]);
  const [categories, setCategories] = useState<MenuCategory[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch menus
    fetchMenuData(parseInt(window.sessionStorage.getItem('Businessid') || '0', 10))
      .then((data) => setMenus(data))
      .catch((error) => console.error('Error fetching menus:', error));

    // Fetch menu categories
    FetchMenuCategories()
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error fetching menu categories:', error));
  }, []);

  const onSubmit = async (data: MenuFormData) => {
    setLoading(true);
    try {
      if (imageFile) {
        if (editMode && selectedMenu) {
          const updatedMenu: Menu = {
            ...data,
            id: selectedMenu.id,
            slug: selectedMenu.slug,
            average_rating: selectedMenu.average_rating || 0,
            reviews: selectedMenu.reviews || [],
            business: selectedMenu.business,
          };

          await SaveMenu(updatedMenu, imageFile);
          setMenus((prev) => prev.map((menu) => (menu.id === updatedMenu.id ? updatedMenu : menu)));
        } else {
          const newMenu: Menu = {
            ...data,
            id: 0,
            slug: '',
            average_rating: 0,
            reviews: [],
            business: {
              category: '',
              category_id: 0,
              cellnumber: '',
              contactperson: '',
              description: '',
              email: '',
              id: '',
              image_path: '',
              name: '',
              slug: '',
            },
          };

          await SaveMenu(newMenu, imageFile);
          setMenus((prev) => [...prev, newMenu]);
        }

        setOpenModal(false); // Dismiss modal on success
        reset();
        setEditMode(false);
        setSelectedMenu(null);
      }
    } catch (error) {
      console.error('Error saving menu:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (menu: Menu) => {
    setSelectedMenu(menu);
    setEditMode(true);
    setOpenModal(true);
    reset(menu);
    setValue('image_path', menu.image_path);
  };

  const handleDeactivate = async (menuId: number) => {
    try {
      await axios.patch(`/api/menus/${menuId}`, { status: 'unavailable' });
      setMenus((prev) =>
        prev.map((menu) => (menu.id === menuId ? { ...menu, status: 'unavailable' } : menu))
      );
    } catch (error) {
      console.error('Error updating menu status:', error);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setValue('image_path', reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <AdminLayout>
      <Typography variant="h4" gutterBottom>
        Manage Menus
      </Typography>
      <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
        Add New Menu
      </Button>

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Average Rating</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menus.map((menu) => (
              <TableRow key={menu.id}>
                <TableCell>{menu.name}</TableCell>
                <TableCell>{menu.description}</TableCell>
                <TableCell>${menu.price.toFixed(2)}</TableCell>
                <TableCell>{menu.status}</TableCell>
                <TableCell>{menu.average_rating}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(menu)}>Edit</Button>
                  <Button onClick={() => handleDeactivate(menu.id)} color="secondary">
                    Deactivate
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for creating/editing menu */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>
            {editMode ? 'Edit Menu' : 'Create Menu'}
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Name"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Description"
                        fullWidth
                        multiline
                        rows={3}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="price"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Price"
                        type="number"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        onChange={(e) =>
                          field.onChange(e.target.value === '' ? '' : parseFloat(e.target.value))
                        }
                        value={field.value || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="menucategory_id"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        select
                        {...field}
                        label="Menu Category"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.id ?? ''}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={field.value === 'available'} />}
                        label="Available"
                        onChange={(e) => {
                            const target = e.target as HTMLInputElement; // Type assertion
                            field.onChange(target.checked ? 'available' : 'unavailable');
                          }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" component="label">
                    Upload Image
                    <input type="file" hidden onChange={handleImageChange} />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    {editMode ? 'Update Menu' : 'Create Menu'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </Modal>
    </AdminLayout>
  );
};

export default BusinessMenus;