import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { VisitorTracker } from '../../../domain/Types/VisitorTracker';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { GetVisitsByDate } from '../../../infrastructure/api/UtilityAPIs/UsertrackingAPI';
import FHPortalLayout from '../Layouts/FHPortalLayout';

const Visitsperday = () => {
  const { state } = useLocation();
  const [filteredVisitors, setFilteredVisitors] = useState<VisitorTracker[]>([]);

  useEffect(() => {
    console.log('Received state:', state.visitors);
    console.log('Selected Date:', state.date);

    if (!state.date) {
      console.error('No date provided.');
      return; // Early exit if no date is provided
    }

    if (state && state.visitors) {
      const visitors: VisitorTracker[] = state.visitors;

      // Filter visitors based on the selected date
      const filtered = visitors.filter(visitor => {
        if (visitor.created_at) {
          // Convert both dates to 'YYYY-MM-DD' format for comparison
          const visitorDate = new Date(visitor.created_at).toISOString().split('T')[0];
          const selectedDate = new Date(state.date).toISOString().split('T')[0];

          console.log(`Visitor Date: ${visitorDate}, Selected Date: ${selectedDate}`); // Log each comparison

          return visitorDate === selectedDate;
        }
        return false;
      });

      setFilteredVisitors(filtered);
    }
  }, [state.date, state]);

  return (
    <FHPortalLayout>
      <Typography variant="h4">Visitors on {state.date} ({filteredVisitors.length} total)</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Visitor Details Table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>User Agent</TableCell>
              <TableCell>Referrer</TableCell>
              <TableCell>Page Visited</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVisitors.length > 0 ? (
              filteredVisitors.map((visitor, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{visitor.ip_address || 'N/A'}</TableCell>
                  <TableCell>{visitor.user_agent || 'N/A'}</TableCell>
                  <TableCell>{visitor.referrer || 'Direct'}</TableCell>
                  <TableCell>{visitor.page_visited || 'N/A'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No visitors found for this date.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FHPortalLayout>
  );
};

export default Visitsperday;