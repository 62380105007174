import { useEffect, useState } from 'react';
import axios from 'axios';
import DriverLayout from './Components/Layouts/DriverLayout';
import { Button, Card, CardContent, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Order } from '../../domain/Types/Order';
import { FetchOrderDriverAssign, OrderDriverAssign, SearchOrders } from '../../infrastructure/api/Order/OrderAPI';
import { OrderSearch } from '../../domain/Types/OrderSearch';
import { DetailedOrder } from '../../domain/Types/DetailedOrder';
import { OrderDriverAssignment } from '../../domain/Types/OrderDriverAssignment';
import Swal from 'sweetalert2';

const DriverOrders = () => {
  const [orders, setOrders] = useState<DetailedOrder[]>([]);
  const [assignedOrders, setAssignedOrders] = useState<OrderDriverAssignment[]>([]);
  const win = window.sessionStorage;

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  const businessIdString = win.getItem("Businessid");
  const businessId = businessIdString ? parseInt(businessIdString) : 0;  

  useEffect(() => {
    console.log(businessId);
    console.log(userId);
    const fetchOrders = async () => {
      const search: OrderSearch = {
        id: 3,
        user_id: null,
        business_id: businessId,
        status: ["Packaged", "Out for Delivery"],
        order_number: "",
        contact_phone: "",
        total_amount: "",
        delivery_address: "",
        delivery_time: "",
        latitude: "",
        longitude: "",
        note: "",
        cartsession: "",
        created_at: "",
        updated_at: "",
      };
  
      try {
        const orderdata = await SearchOrders(search);
        setOrders(orderdata);

        // Fetch assigned orders

        const response = await FetchOrderDriverAssign();  
        console.log(response);      
        setAssignedOrders(response);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);  

  // Function to assign an order to the driver
  const assignOrder = async (orderId: number) => {
    try {
      const orderAssign: OrderDriverAssignment = {
        id: 0,
        order_id: orderId,
        user_id: userId,
        assignment_time: new Date().toISOString().slice(0, 19).replace('T', ' '),
        status: 'assigned',
        created_at: '',
        updated_at: ''
      };
      const orderass = await OrderDriverAssign(orderAssign);
      Swal.fire('Order assigned successfully!');

      // Refresh assigned orders list
      setAssignedOrders((prev) => [...prev, orderAssign]);
    } catch (error) {
      console.error('Error assigning order:', error);
      Swal.fire('Failed to assign order.');
    }
  };

  return (
    <DriverLayout>
      <Typography variant="h4" gutterBottom>
        Available Orders
      </Typography>
      <Grid container spacing={2}>
        {orders.length > 0 ? (
          orders.map((order) => (
            <Grid item xs={12} sm={6} md={4} key={order.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Order #{order.order_number}</Typography>
                  <Typography variant="body2">Address: {order.deliverydetail?.delivery_address}</Typography>
                  <Typography variant="body2">Total: MK{order.total_amount}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => order.id !== null && assignOrder(order.id)}
                    style={{ marginTop: '10px' }}
                  >
                    Pick for Delivery
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1">No available orders at the moment.</Typography>
        )}
      </Grid>

      <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
        Assigned Orders
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Assignment Time</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignedOrders.length > 0 ? (
              assignedOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.order_id}</TableCell>
                  <TableCell>{order.user_id}</TableCell>
                  <TableCell>{new Date(order.assignment_time).toLocaleString()}</TableCell>
                  <TableCell>{order.status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No assigned orders at the moment.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DriverLayout>
  );
};

export default DriverOrders;