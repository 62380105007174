import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../domain/Types/User';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OrderSearch } from '../../domain/Types/OrderSearch';
import { SearchOrders } from '../../infrastructure/api/Order/OrderAPI';
import { DetailedOrder } from '../../domain/Types/DetailedOrder';
import SpinnerModal from '../app/SpinnerModal';
import AppLayout from './Components/Layouts/App/AppLayout';

const MyOrders = () => {
  const win = window.sessionStorage;
  const [orders, setOrders] = useState<DetailedOrder[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Add a loading state

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  const user: User = {
    id: userId,
    business_id: 0,
    firstname: "Xavier",
    lastname: "Khonje",
    email: "xavier.khonje@foodhut.mw",
    email_verified_at: "",
    password: "Kangoo**123",
    device_token: "",
    remember_token: "",
    acl: "customer",
    created_at: "12-12-12",
    updated_at: "12-12-12",
  };

  const ordersearch: OrderSearch = {
    id: 1,
    user_id: user.id,
    business_id: null,
    status: ["Order Confirmed", "Preparing", "Delayed", "Packaged", "Ready for collection", "Collected", "Out for Delivery", "Delivered"],
    order_number: "",
    contact_phone: "",
    total_amount: "",
    delivery_address: "",
    delivery_time: "",
    latitude: "",
    longitude: "",
    note: "",
    cartsession: "",
    created_at: "",
    updated_at: "",
  };

  console.log(ordersearch);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await SearchOrders(ordersearch);
        setOrders(data);
        console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleTrackOrder = (order: DetailedOrder) => {
    navigate("/TrackOrder", { state: { order } });
  };

  return (
    <AppLayout>
      <SpinnerModal isLoading={loading} message="Loading your orders..." /> {/* Add SpinnerModal */}

      {!loading && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell align="right">Order Date</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Total Amount</TableCell>
                <TableCell align="right">Track Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{order.order_number}</TableCell>
                  <TableCell align="right">{order.created_at}</TableCell>
                  <TableCell align="right">{order.orderstatus?.status}</TableCell>
                  <TableCell align="right">{order.total_amount}</TableCell>
                  <TableCell align="right">
                    <button onClick={() => handleTrackOrder(order)}>Track Order</button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </AppLayout>
  );
}

export default MyOrders;