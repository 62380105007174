import React, { useState } from 'react';
import {
  TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, Box, Divider, Switch, FormControlLabel, Alert, MenuItem, Select, InputLabel, FormControl,
  SelectChangeEvent
} from '@mui/material';
import AdminLayout from '../../customer/Components/Layouts/Admin/AdminLayout';
import { BusinessPayment } from '../../../domain/Types/BusinessPayment';
import { SaveBusinessPayment } from '../../../infrastructure/api/BusinessPaymentDetails/BusinessPaymentDetails';

const PaymentMethods: React.FC = () => {
  const [paymentMethods, setPaymentMethods] = useState<BusinessPayment[]>([]);
  const [methodName, setMethodName] = useState<string>('');
  const [provider, setProvider] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [branchName, setBranchName] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [instructions, setInstructions] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(true);
  const win = window.sessionStorage;

  const businessIdString = win.getItem("businessID");
  const BusinessId = businessIdString ? parseInt(businessIdString) : 0;

  // Error handling state
  const [errors, setErrors] = useState({
    method_name: '',
    provider: '',
    account_number: ''
  });

  const validateForm = () => {
    let formErrors = { method_name: '', provider: '', account_number: '' };
    let isValid = true;

    if (!methodName) {
      formErrors.method_name = 'Payment method name is required';
      isValid = false;
    }
    if (!provider) {
      formErrors.provider = 'Provider is required';
      isValid = false;
    }
    if (!accountNumber) {
      formErrors.account_number = 'Account number is required';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSavePaymentMethod = async () => {
    if (validateForm()) {
      const newPaymentMethod: BusinessPayment = {
        id: paymentMethods.length + 1,
        business_id: BusinessId,
        method_name: methodName,
        provider: provider,
        account_number: accountNumber,
        account_name: accountName,
        branch_name: branchName,
        bank_name: bankName,
        instructions: instructions,
        is_active: isActive,
      };

      
      //Persist the Payment Method
      try {
        const data = await SaveBusinessPayment(newPaymentMethod);
        console.log(data);
        //setBusinessAdd(data);
        //setPaymentMethods([...paymentMethods, newPaymentMethod]);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }

      // Clear the form fields after saving
      setMethodName('');
      setProvider('');
      setAccountNumber('');
      setAccountName('');
      setBranchName('');
      setBankName('');
      setInstructions('');
      setIsActive(true);
      setErrors({ method_name: '', provider: '', account_number: '' });
    }
  };

  const handleMethodNameChange = (event: SelectChangeEvent<string>) => {
    setMethodName(event.target.value);
    setProvider(''); // Reset provider when method changes
  };

  const handleProviderChange = (event: SelectChangeEvent<string>) => {
    setProvider(event.target.value);
  };

  const mobileProviders = ['TNM Mpamba', 'Airtel Money'];
  const bankProviders = ['Bank'];

  return (
    <AdminLayout>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h5">Payment Methods</Typography>
        <Divider sx={{ marginBottom: 2 }} />

        {/* Form to add payment methods */}
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 3 }}>
          
          {/* Payment Method Name Dropdown */}
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="payment-method-label">Payment Method Name</InputLabel>
            <Select
              labelId="payment-method-label"
              value={methodName}
              onChange={handleMethodNameChange}
              label="Payment Method Name"
              required
              error={!!errors.method_name}
            >
              <MenuItem value="Mobile Payment">Mobile Payment</MenuItem>
              <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
            </Select>
            {errors.method_name && <Typography color="error">{errors.method_name}</Typography>}
          </FormControl>

          {/* Conditional Provider Dropdown */}
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="provider-label">Provider</InputLabel>
            <Select
              labelId="provider-label"
              value={provider}
              onChange={handleProviderChange}
              label="Provider"
              required
              error={!!errors.provider}
            >
              {methodName === 'Mobile Payment' ? (
                mobileProviders.map((provider) => (
                  <MenuItem key={provider} value={provider}>
                    {provider}
                  </MenuItem>
                ))
              ) : methodName === 'Bank Transfer' ? (
                bankProviders.map((provider) => (
                  <MenuItem key={provider} value={provider}>
                    {provider}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  Please select a payment method first
                </MenuItem>
              )}
            </Select>
            {errors.provider && <Typography color="error">{errors.provider}</Typography>}
          </FormControl>

          <TextField
            label="Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            variant="outlined"
            required
            error={!!errors.account_number}
            helperText={errors.account_number}
          />
          <TextField
            label="Account Name"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            variant="outlined"
          />
          {methodName === 'Bank Transfer' && (
            <>
              <TextField
                label="Branch Name"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                variant="outlined"
              />
              <TextField
                label="Bank Name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                variant="outlined"
              />
            </>
          )}
          <TextField
            label="Instructions"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            variant="outlined"
            multiline
            rows={3}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                color="primary"
              />
            }
            label="Is Active"
          />
          <Button variant="contained" color="primary" onClick={handleSavePaymentMethod}>
            Save
          </Button>
        </Box>

        {/* Table to list all saved payment methods */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Method Name</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>Account Name</TableCell>
                <TableCell>Branch Name</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Instructions</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No Payment Methods Saved
                  </TableCell>
                </TableRow>
              ) : (
                paymentMethods.map((method) => (
                  <TableRow key={method.id}>
                    <TableCell>{method.id}</TableCell>
                    <TableCell>{method.method_name}</TableCell>
                    <TableCell>{method.provider}</TableCell>
                    <TableCell>{method.account_number}</TableCell>
                    <TableCell>{method.account_name || '-'}</TableCell>
                    <TableCell>{method.branch_name || '-'}</TableCell>
                    <TableCell>{method.bank_name || '-'}</TableCell>
                    <TableCell>{method.instructions || '-'}</TableCell>
                    <TableCell>{method.is_active ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </AdminLayout>
  );
};

export default PaymentMethods;