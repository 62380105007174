import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';
import DriverLayout from './Components/Layouts/DriverLayout';
import { DetailedOrder } from '../../domain/Types/DetailedOrder'; // Ensure this type is properly defined
import { DriverOrderSearch } from '../../domain/Types/DriverOrderSearch'; // Ensure this type is properly defined
import { FetchDriverOrderInprogress, UpdateDriverOrderStatus, UpdateOrderStatus } from '../../infrastructure/api/Order/OrderAPI'; // Assuming this is an API helper
import { OrderStatus, ReceivedOrderDriverAssignment } from '../../domain/Types/ReceivedOrderDriverAssignment';
import { DriverStatusUpdatePayload } from '../../domain/Types/DriverStatusUpdatePayload';
import Swal from 'sweetalert2';

const OrderInProgress = () => {
  // Define the state with the correct type (use DetailedOrder[] if needed)
  const [inProgressOrders, setInProgressOrders] = useState<ReceivedOrderDriverAssignment[]>([]);
  const win = window.sessionStorage;

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  useEffect(() => {
    const search: DriverOrderSearch = {
      user_id: userId, // Replace with dynamic user id if needed
      DriverStatus: [OrderStatus.ASSIGNED, OrderStatus.IN_TRANSIT], // Use the enum for consistency
      orderstatus: OrderStatus.DELIVERED, // Update if needed
    };

    // Fetch in-progress orders
    const fetchOrders = async () => {
      try {
        const response = await FetchDriverOrderInprogress(search);
        console.log(response);
        setInProgressOrders(response);
      } catch (error) {
        console.error('Error fetching in-progress orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const handleStatusUpdate = async (orderId: number, newStatus: OrderStatus) => {
    const payload: DriverStatusUpdatePayload = {
      order_id: orderId,
      user_id: userId,
      status: newStatus
    }

    try {

      await UpdateDriverOrderStatus(payload);
      setInProgressOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
      console.log(`Order ${orderId} updated to status: ${newStatus}`);
      Swal.fire(`Order ${orderId} updated to status: ${newStatus}`);
    } catch (error) {
      console.error(`Failed to update order ${orderId}:`, error);
    }
  };

  return (
    <DriverLayout>
      <Typography variant="h4" gutterBottom>
        Orders in Progress
      </Typography>
      {inProgressOrders.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Order Number</TableCell>
                <TableCell>Delivery Address</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {inProgressOrders.map((Driverorder) => (
                    <TableRow key={Driverorder.id}>
                    <TableCell>{Driverorder.order_id}</TableCell>
                    <TableCell>{Driverorder.order?.order_number || 'N/A'}</TableCell>
                    <TableCell>{Driverorder.order?.delivery_address || 'N/A'}</TableCell>
                    <TableCell>MK{Driverorder.order?.total_amount || 'N/A'}</TableCell>
                    <TableCell>{Driverorder.status || 'N/A'}</TableCell> {/* Use assignment status */}
                    <TableCell>
                        <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleStatusUpdate(Driverorder.order_id, OrderStatus.IN_TRANSIT)}
                        >
                        Mark In Transit
                        </Button>
                        <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleStatusUpdate(Driverorder.order_id, OrderStatus.DELIVERED)}
                        >
                        Mark Delivered
                        </Button>
                    </TableCell>
                    </TableRow>
                ))}
            </TableBody>

          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No orders in progress at the moment.</Typography>
      )}
    </DriverLayout>
  );
};

export default OrderInProgress;