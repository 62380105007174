import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TablePagination,
  TextField,
  Button
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ActiveElement, ChartEvent } from 'chart.js';
import FHPortalLayout from '../Layouts/FHPortalLayout';
import { VisitorTracker } from '../../../domain/Types/VisitorTracker';
import { GetVisitors } from '../../../infrastructure/api/UtilityAPIs/UsertrackingAPI';
import { useNavigate, useLocation } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const VisitorTrack = () => {
  const [visitors, setVisitors] = useState<VisitorTracker[]>([]);
  const [filteredVisitors, setFilteredVisitors] = useState<VisitorTracker[]>([]);
  const [totalVisits, setTotalVisits] = useState(0);
  const [visitCounts, setVisitCounts] = useState<{ date: string; count: number }[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const location = useLocation(); // To detect navigation changes

  // Filter states
  const [ipFilter, setIpFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  const fetchData = async () => {
    try {
      const data = await GetVisitors();
      setVisitors(data);
      setFilteredVisitors(data);
      setTotalVisits(data.length);

      const countsByDate: { [key: string]: number } = {};
      data.forEach((visitor) => {
        const createdAt = visitor.created_at;
        if (createdAt) {
          const date = new Date(createdAt).toLocaleDateString();
          countsByDate[date] = (countsByDate[date] || 0) + 1;
        }
      });

      const countsArray = Object.keys(countsByDate).map(date => ({
        date,
        count: countsByDate[date]
      }));
      setVisitCounts(countsArray);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location.key]); // Retriggers data fetch when navigating back to this page

  // Chart data and options
  const chartData = {
    labels: visitCounts.map((item) => item.date),
    datasets: [
      {
        label: 'Visits',
        data: visitCounts.map((item) => item.count),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' as const },
      title: { display: true, text: 'Visits Over Time' },
    },
    onClick: (event: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const date = visitCounts[index].date;

        console.log(visitors);
        console.log(date);
        // Navigate to the detail page for the selected date
        navigate('/Visitsperday', { state: { visitors, date } });
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilters = () => {
    const filtered = visitors.filter(visitor => {
      const matchesIP = ipFilter ? visitor.ip_address?.includes(ipFilter) || false : true;
      const matchesDate = dateFilter ? 
        (visitor.created_at ? new Date(visitor.created_at).toLocaleDateString() === dateFilter : false) 
        : true;
      
      return matchesIP && matchesDate;
    });
    setFilteredVisitors(filtered);
  };
  
  return (
    <FHPortalLayout>
      <Typography variant="h4" component="h1" gutterBottom>
        Visitor Tracker
      </Typography>

      <Box sx={{ my: 2 }}>
        <Typography variant="h6">Total Visits: {totalVisits}</Typography>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>Visits Over Time</Typography>
        <Bar data={chartData} options={chartOptions} />
      </Box>

      {/* Filter Inputs */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Filter by IP"
          variant="outlined"
          value={ipFilter}
          onChange={(e) => setIpFilter(e.target.value)}
        />
        <TextField
          label="Filter by Date (MM/DD/YYYY)"
          variant="outlined"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
        <Button variant="contained" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="Visitor Tracker Table">
          <TableHead>
            <TableRow>
              <TableCell>IP Address</TableCell>
              <TableCell>User Agent</TableCell>
              <TableCell>Referrer</TableCell>
              <TableCell>Page Visited</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVisitors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((visitor, index) => (
              <TableRow key={index}>
                <TableCell>{visitor.ip_address || 'N/A'}</TableCell>
                <TableCell>{visitor.user_agent || 'N/A'}</TableCell>
                <TableCell>{visitor.referrer || 'Direct'}</TableCell>
                <TableCell>{visitor.page_visited || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredVisitors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </FHPortalLayout>
  );
};

export default VisitorTrack;