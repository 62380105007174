import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AdminLayout from '../../customer/Components/Layouts/Admin/AdminLayout';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Promotion } from '../../../domain/Types/Promotion';
import { SavePromotionTemplate, fetchPromotions, UpdatePromotion, SavePromotionTemplateold, UpdatePromotionold } from '../../../infrastructure/api/Promotions/PromotionAPI';
import Swal from 'sweetalert2';

const promotionSchema = z.object({
  name: z.string().min(1, 'Promotion name is required'),
  discount_percentage: z.number().min(0).max(100).nullable().optional(),
  discount_amount: z.number().min(0).nullable().optional(),
  start_date: z.string().min(1, 'Start date is required'),
  end_date: z.string().min(1, 'End date is required'),
  image_path: z.string().nullable(),
  description: z.string().nullable(),
});

type PromotionFormInputs = z.infer<typeof promotionSchema>;
//const BizID = parseInt(window.sessionStorage.getItem("businessID") || "0", 10);
const BizID = parseInt(window.sessionStorage.getItem('Businessid') || '0', 10);

const Promotions = () => {
  const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm<PromotionFormInputs>({
    resolver: zodResolver(promotionSchema),
  });

  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingPromotionId, setEditingPromotionId] = useState<number | null>(null);

  useEffect(() => {
    const loadPromotions = async () => {
      try {
        const data = await fetchPromotions();
        setPromotions(data);
      } catch (error) {
        console.error("Error fetching promotions:", error);
      } finally {
        setLoading(false);
      }
    };
    
    loadPromotions();
  }, []);

  const onSubmit = async (data: PromotionFormInputs) => {
    if (editingPromotionId !== null) {
      // Editing existing promotion
      const updatedPromotion: Promotion = {
        id: editingPromotionId,
        ...data,
        business_id: BizID,
        slug: null
      };

      await UpdatePromotionold(updatedPromotion);
      setPromotions((prev) =>
        prev.map((promo) => (promo.id === editingPromotionId ? updatedPromotion : promo))
      );
      Swal.fire("Success!", "Promotion Template Updated Successfully!", "success");
      setEditingPromotionId(null);
    } else {
      // Adding new promotion
      const newPromotion: Promotion = {
        id: promotions.length + 1,
        ...data,
        business_id: BizID,
        image_path: '',
        description: '',
        slug: null
      };

      await SavePromotionTemplateold(newPromotion);
      setPromotions((prev) => [...prev, newPromotion]);
      Swal.fire("Success!", "Promotion Template Created Successfully!", "success");
    }
    reset();
  };

  const handleEdit = (promotion: Promotion) => {
    setEditingPromotionId(promotion.id);
    setValue("name", promotion.name);
    setValue("discount_percentage", promotion.discount_percentage ? parseFloat(promotion.discount_percentage.toString()) : null);
    setValue("discount_amount", promotion.discount_amount ? parseFloat(promotion.discount_amount.toString()) : null);
    setValue("start_date", promotion.start_date);
    setValue("end_date", promotion.end_date);
  };

  return (
    <AdminLayout>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            {editingPromotionId ? "Edit Promotion Template" : "Create Promotion Template"}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Promotion Name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="image_path"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="image"
                      error={!!errors.image_path}
                      helperText={errors.image_path?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Description"
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="discount_percentage"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Discount Percentage"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      inputProps={{ step: "0.1" }}
                      value={field.value ?? ""}
                      onChange={(e) => field.onChange(parseFloat(e.target.value) || 0)}
                      error={!!errors.discount_percentage}
                      helperText={errors.discount_percentage?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="discount_amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Discount Amount"
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      inputProps={{ step: "0.01" }}
                      value={field.value ?? ""}
                      onChange={(e) => field.onChange(parseFloat(e.target.value) || 0)}
                      error={!!errors.discount_amount}
                      helperText={errors.discount_amount?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="start_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.start_date}
                      helperText={errors.start_date?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="end_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.end_date}
                      helperText={errors.end_date?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" type="submit">
                  {editingPromotionId ? "Update Promotion Template" : "Create Promotion Template"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Typography variant="h5" sx={{ mt: 4 }} gutterBottom>
          Available Promotions
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Promotion Name</TableCell>
                  <TableCell>Discount Percentage</TableCell>
                  <TableCell>Discount Amount</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Business ID</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promotions.map((promotion) => (
                  <TableRow key={promotion.id}>
                    <TableCell>{promotion.name}</TableCell>
                    <TableCell>{promotion.discount_percentage ?? '-'}</TableCell>
                    <TableCell>{promotion.discount_amount ?? '-'}</TableCell>
                    <TableCell>{promotion.start_date}</TableCell>
                    <TableCell>{promotion.end_date}</TableCell>
                    <TableCell>{promotion.business_id}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEdit(promotion)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </AdminLayout>
  );
};

export default Promotions;