import React from 'react'
import DriverLayout from './Components/Layouts/DriverLayout'

const IncidentReport = () => {
  return (
    <DriverLayout>
      IncidentReport
    </DriverLayout>
  )
}

export default IncidentReport