import { useState } from 'react';
import {
  TextField,
  Button,
  FormControl,
} from '@mui/material';
import CustomerLayout from '../Components/Layouts/Customer/CustomerLayout';
import { Link } from "react-router-dom";
import { DeliveryDetails } from '../../../domain/Types/DeliveryDetails';
import AppLayout from '../Components/Layouts/App/AppLayout';

function UpdateDeliveryInfo() {
  const win = window.sessionStorage;

  const [delivery, setDelivery] = useState<DeliveryDetails>({
    id: 0,
    user_id: 0,
    delivery_address: '',
    latitude: 0,
    longitude: 0,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDelivery((prevDelivery) => ({
      ...prevDelivery,
      [name]: name === 'latitude' || name === 'longitude' ? Number(value) : value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Submit the updated delivery information to your backend or handle it accordingly
    console.log('Updated Delivery Information:', delivery);
  };

  return (
    <AppLayout>
      <span className='font-bold'>Delivery Information</span><br />
      Welcome {win.getItem("fname")} {win.getItem("lname")}
      <ul>
        <li><Link to="/PersonalInfo">Personal Information</Link></li>
        <li><Link to="/PaymentInfo">Payment Information</Link></li>
        <li><Link to="/DeliveryInfo">Delivery Information</Link></li>
      </ul>

      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Delivery Address"
            name="delivery_address"
            value={delivery.delivery_address}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Latitude"
            type="number"
            name="latitude"
            value={delivery.latitude}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Longitude"
            type="number"
            name="longitude"
            value={delivery.longitude}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
        </FormControl>

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update Delivery Information
        </Button>
      </form>
    </AppLayout>
  );
}

export default UpdateDeliveryInfo;