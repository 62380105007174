import React from 'react'
import AppLayout from '../App/AppLayout'

const RefundPolicy = () => {
  return (
    <AppLayout>
      <div>RefundPolicy - Under Construction</div>
    </AppLayout>

  )
}

export default RefundPolicy