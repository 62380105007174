// src/pages/NoResultsPage.tsx

import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AppLayout from './Components/Layouts/App/AppLayout';

const NoResultsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Access state from navigation
  const searchTerm = location.state?.searchTerm || 'your search'; // Default message if state is undefined

  return (
    <AppLayout>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h4" gutterBottom>
        No Results Found
      </Typography>
      <Typography variant="body1" paragraph>
        Sorry, we couldn’t find any matches for <strong>{`"${searchTerm}"`}</strong>.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/')}>
        Go Back to Home
      </Button>
    </Box>
    </AppLayout>
  );
};

export default NoResultsPage;