import { Box, Typography, CircularProgress, Card, CardMedia } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { getMappedPromotions } from "../../../infrastructure/api/Promotions/PromotionAPI";
import { MappedPromotions } from "../../../domain/Types/MappedPromotions";
import { fetchPromotions } from "../../../infrastructure/api/Promotions/PromotionAPI";
import { Promotion } from "../../../domain/Types/Promotion";


const ScrollPromotion = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Loading state
    const [mappedPromotions, setMappedPromotions] = useState<MappedPromotions[]>([]);     
    const [Promotions, setPromotions] = useState<Promotion[]>([]);     
  
    const handleCardClick = (promotionId: number) => {
      navigate('/Promotionsdetails', {
        state: { promotionId },
      });
    };

    useEffect(() => {
        const loadData = async () => {
          try {
            const promos = await fetchPromotions();
            setPromotions(promos);
            console.log(Promotions);
          } catch (error) {
            console.error("Error loading data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        loadData();
      }, []);      
  
    // Custom arrow components
    const CustomArrow = (props: any) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
             style={{
              ...style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.5)', // Black with low opacity
              borderRadius: '50%',
              width: '35px',
              height: '35px',            
          }}
          onClick={onClick}
        />
      );
    };
  
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <CustomArrow />,
      prevArrow: <CustomArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    const placeholderImage = 'https://turmericausa.com/wp-content/uploads/2023/07/Catering_mobile-banner.png';
  
    return (
      <Box sx={{ padding: 1 }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'black' }}>
          Promotions
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Slider {...sliderSettings}>
            {Promotions.map((promo) => (
              <Card
                key={promo.id}
                onClick={() => handleCardClick(promo.id)}
                sx={{ position: 'relative', maxWidth: 305, mx: 1, cursor: 'pointer' }}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={promo.image_path || placeholderImage}
                  alt={promo.name}
                  sx={{ objectFit: 'cover', width: '100%' }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '40%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 1,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {promo.name}
                  </Typography>
                  <Typography variant="body1">
                    {promo.discount_percentage
                      ? `Discount: ${promo.discount_percentage}%`
                      : promo.discount_amount
                      ? `Discount: $${promo.discount_amount}`
                      : 'No discount available'}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {`Valid from: ${promo.start_date} to ${promo.end_date}`}
                  </Typography>
                </Box>
              </Card>
            ))}
          </Slider>
        )}
      </Box>
    );
  }

  export default ScrollPromotion;