import React, { useState } from "react";
import { useForm, Controller, SubmitHandler, FieldValues } from "react-hook-form";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from "@mui/material";
import { useNavigate, Link } from 'react-router-dom';
import Swal from "sweetalert2";
import SpinnerModal from "../SpinnerModal";
import { LoginUser } from "../../../infrastructure/api/User/UserAPI";
import { User } from "../../../domain/Types/User";
import { Fcmtoken } from "../../../domain/Types/Fcmtoken";
import { storeTokenData } from "../../../infrastructure/api/FcmToken/TokenFcm";

const SigninModal: React.FC<{ open: boolean; handleClose: () => void; onSignInFeedback: (success: boolean) => void }> = ({ open, handleClose, onSignInFeedback }) => {
    const navigate = useNavigate();
    const win = window.sessionStorage;

    const [loginerrors, setErrors] = useState([]);
    const [connect, setConnect] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();

    const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
        setErrors([]);
        setMessage("");

        const user: User = {
            id: 0,
            business_id: 0,
            firstname: "xavier",
            lastname: "xavier",
            email: data.email,
            email_verified_at: "xavier.khonje@hotmail.com",
            password: data.password,
            device_token: data.device_token,
            remember_token: "sdseweewe",
            acl: "customer",
            created_at: "12-12-12",
            updated_at: "12-12-12",
        };

        const UserLogin = async () => {
            try {
                setIsLoading(true);
                //setConnect("Sign-in...");

                const data = await LoginUser(user);
                const UserFcmToken: string | null = localStorage.getItem('fcmToken'); //win.getItem('fcmToken');
                console.log(data.id);
                console.log(UserFcmToken);

                if (UserFcmToken && UserFcmToken.trim() !== '') {
                    const UserToken: Fcmtoken = {
                        user_id: data.id,
                        token: UserFcmToken
                    };
                    storeTokenData(UserToken);
                } else {
                    console.error('Token is not available or is empty');
                }

                // Feedback to the parent that login was successful
                onSignInFeedback(true);
                console.log(data);
                switch (data.acl) {
                    case 'customer':
                        win.setItem('Token', data.remember_token);
                        win.setItem('userid', data.id.toString());
                        win.setItem('fname', data.firstname);
                        win.setItem('lname', data.lastname);
                        win.setItem('email', data.email);
                        navigate('/');
                        break; 
                    case 'driver':                        
                        win.setItem('Token', data.remember_token);
                        win.setItem('userid', data.id.toString());
                        win.setItem('Businessid', data.business_id.toString());
                        win.setItem('fname', data.firstname);
                        win.setItem('lname', data.lastname);
                        win.setItem('email', data.email);
                        navigate('/Driverhome');
                        break;                  
                    case 'Administrator':
                        break;   
                    case 'superadmin':
                        win.setItem('Token', data.remember_token);
                        win.setItem('userid', data.id.toString());
                        win.setItem('fname', data.firstname);
                        win.setItem('lname', data.lastname);
                        win.setItem('email', data.email);      
                        navigate('/FoodhutDashboard');                  
                        break;   
                    case 'user':
                        setMessage('Account Not Active, Please contact Administrator');
                        break;    
                    case 'businessowner':
                        win.setItem('Token', data.remember_token);
                        win.setItem('userid', data.id.toString());
                        win.setItem('Businessid', data.business_id.toString());
                        win.setItem('fname', data.firstname);
                        win.setItem('lname', data.lastname);
                        win.setItem('email', data.email);
                        navigate('/Dashboard');
                        break;  
                    default:
                        setMessage('Account Not Active, Please contact Administrator');    
                }

                Swal.fire({
                    title: 'SUCCESS',
                    text: 'Login Successful',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500                
                });
                // Close the modal only after successful login
                handleClose();

            } catch (error: any) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 401) {
                    // Handle duplicate transaction ID error
                    Swal.fire({
                      title: 'Credentials Do not Match',
                      text: 'Please verify your Credentials and try again.',
                      icon: 'info',
                      showConfirmButton: true
                    });
                    navigate('/');
                  } else {
                    // Handle general errors
                    Swal.fire({
                      title: 'Unable to Login',
                      text: 'Please Click Forgot Password to Reset',
                      icon: 'info',
                      showConfirmButton: false,
                      timer: 1500
                    });
                    navigate('/');
                  }
                onSignInFeedback(false); // Feedback to parent if login fails
            } finally {
                setIsLoading(false);
            }
        };

        UserLogin();
        reset();
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-react-Form">
                        <span className="text-red-500">{loginerrors}{message}</span>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email address is required',
                                pattern: {
                                    value: emailRegex,
                                    message: 'Invalid email address',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="email"
                                    label="Email Address"
                                    variant="outlined"
                                    placeholder="Email Address"
                                    fullWidth    
                                    margin="normal"                                             
                                    error={!!errors.email}
                                    helperText={errors.email && "Email is required"}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="password"
                                    type="password"                                                                    
                                    label="Password"
                                    variant="outlined"
                                    placeholder="Enter Password"
                                    fullWidth      
                                    margin="normal"                                                                                                           
                                    error={!!errors.password}
                                    helperText={errors.password && "Password is required"}
                                />
                            )}
                        />
                        <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
                            <Controller
                                name="remember_me"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
                                        <input
                                            {...field}
                                            type="checkbox"
                                            id="remember_me"
                                            onChange={(e) => setValue("remember_me", e.target.checked)}
                                        />
                                        <label htmlFor="remember_me">Remember me</label>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col-md-8 mt-10 mb-20 text-left text-md-right">
                            <Link to={`/ForgotPassword`}> <span className="ForgotPassword">Forgotten password?</span></Link><br />
                            <Link to={`/Register`}> <span className="Register">Register</span></Link>
                        </div>
                        <div className="col-md-12">
                        <Button type="submit" className="Loginbutton mt-0" variant="contained" disabled={isLoading}>
                                {isLoading ? <CircularProgress size={24} /> : "Login"}
                            </Button>
                        </div>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <SpinnerModal isLoading={isLoading} message={connect} />
        </Dialog>
    );
}

export default SigninModal;