import React from 'react'
import DriverLayout from './Components/Layouts/DriverLayout'

const RouteOptimization = () => {
  return (
    <DriverLayout>
      RouteOptimization
    </DriverLayout>
  )
}

export default RouteOptimization