import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { Business } from '../../domain/Types/Business';
import AppLayout from './Components/Layouts/App/AppLayout';
import BusinessCard from './Components/OtherComponents/BusinessCard';

const FoodhutSearch = () => {
  const { state } = useLocation();
  const searchResults: Business[] = state?.searchResults || []; // Access search results from navigation state

  const navigate = useNavigate();

  const handleFoodStoreselect = (foodstore: Business) => {
    navigate(`/FoodStores/${foodstore.id}`);
  };

  return (
    <AppLayout>
      <div>
        <Typography variant="h4" gutterBottom>
          Search Results
        </Typography>
        <Grid container spacing={2}>
          {searchResults.length > 0 ? (
            searchResults.map((business) => (
              <Grid item xs={12} sm={6} md={3} key={business.id}> {/* 4 items per row */}
                <BusinessCard foodstore={business} onSelectfoodstore={handleFoodStoreselect} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6">No results found.</Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </AppLayout>
  );
};

export default FoodhutSearch;