import React, { useEffect, useState } from 'react';
import { Typography, Grid, Paper, Button, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import FHPortalLayout from '../Layouts/FHPortalLayout';
import { CustomerJourney } from '../../../domain/Types/CustomerJourney';
import { fetchCustomerJourneyData } from '../../../infrastructure/api/CustomerJourney/CustomerJourneyAPI';

interface Shop {
  id: number;
  name: string;
}

interface FunnelData {
  name: string;
  value: number;
}

const shops: Shop[] = [
  { id: 1, name: 'Shop A' },
  { id: 2, name: 'Shop B' },
  { id: 3, name: 'Shop C' },
  { id: 4, name: 'Shop D' },
  { id: 5, name: 'Shop E' },
];

const Funnel: React.FC = () => {
  const [selectedShop, setSelectedShop] = useState<number>(1);
  const [viewDetail, setViewDetail] = useState<boolean>(false);
  const [funnelData, setFunnelData] = useState<FunnelData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [CustomerJourney, setCustomerJourney] = useState<CustomerJourney[]>([]);
  const [stageCounts, setStageCounts] = useState<FunnelData[]>([]);

  const fetchData = async () => {
    try {
      const data = await fetchCustomerJourneyData();
      setCustomerJourney(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Calculate stage counts from CustomerJourney data
    const counts = CustomerJourney.reduce<Record<string, number>>((acc, journey) => {
      acc[journey.stage] = (acc[journey.stage] || 0) + 1;
      return acc;
    }, {});

    // Transform to FunnelData array
    const stageData: FunnelData[] = Object.keys(counts).map((stage) => ({
      name: stage,
      value: counts[stage],
    }));

    setStageCounts(stageData);
  }, [CustomerJourney]);

  return (
    <FHPortalLayout>
      <Typography variant="h4" gutterBottom>
        Customer Journey and Funnel
      </Typography>
      
      <TextField
        label="Search for a Shop"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      
      <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
        <InputLabel id="shop-select-label">Select Shop</InputLabel>
        <Select
          labelId="shop-select-label"
          value={selectedShop}
          onChange={(e) => setSelectedShop(e.target.value as number)}
        >
          {shops
            .filter((shop) => shop.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((shop) => (
              <MenuItem key={shop.id} value={shop.id}>
                {shop.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      
      <Paper style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h5">
          Funnel Overview for {shops.find((shop) => shop.id === selectedShop)?.name}
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={stageCounts}
              dataKey="value"
              nameKey="name"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {stageCounts.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={['#8884d8', '#82ca9d', '#ffc658', '#ff7300'][index % 4]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
        <Button variant="contained" onClick={() => setViewDetail(!viewDetail)}>
          {viewDetail ? 'View Overview' : 'View Details'}
        </Button>
      </Paper>
      
      {viewDetail && (
        <Paper style={{ padding: '20px' }}>
          <Typography variant="h5" gutterBottom>
            Detailed Customer Journey
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={stageCounts}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      )}
    </FHPortalLayout>
  );
};

export default Funnel;