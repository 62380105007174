import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './foodhut.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ForgotPassword from './Presentation/app/ForgotPassword';
import LandingScreen from './Presentation/app/LandingScreen';
import Home from './Presentation/customer/Home';
import Driverhome from './Presentation/driver/Driverhome';
import Dashboard from './Presentation/admin/Dashboard';
import FoodStore from './Presentation/customer/FoodStore';
import MenuOptions from './Presentation/customer/MenuOptions';
import ShoppingCartPage from './Presentation/customer/ShoppingCartPage';
import CheckOut from './Presentation/customer/CheckOut';
import PaymentConfirmation from './Presentation/customer/PaymentConfirmation';
import MyOrders from './Presentation/customer/MyOrders';
import Favourites from './Presentation/customer/Favourites';
import TrackOrder from './Presentation/customer/TrackOrder';
import Context from './utilities/Context/Context';
import CartProvier from './utilities/Context/ShoppingCartContext';
import Signin from './Presentation/app/Signin';
import Register from './Presentation/app/Register';
import PaymentPreferences from './Presentation/app/PaymentPreferences';
import FoodStoreCategory from './Presentation/customer/FoodStoreCategory';
import Account from './Presentation/customer/Account';
import UpdateDeliveryInfo from './Presentation/customer/MyAccount/UpdateDeliveryInfo';
import UpdatePaymentInfo from './Presentation/customer/MyAccount/UpdatePaymentInfo';
import UpdatePersonalInformation from './Presentation/customer/MyAccount/UpdatePersonalInformation';
import ExtendedSearch from './Presentation/customer/ExtendedSearch';
import AdminOrders from './Presentation/admin/Pages/AdminOrders';
import AdminAccount from './Presentation/admin/Pages/AdminAccount';
import FoodCategory from './Presentation/app/FoodCategory';
import ProductPage from './Presentation/app/ProductPage';
import MenuFeedback from './Presentation/customer/MenuFeedback';
import Reviews from './Presentation/customer/Reviews';
import { messaging, getToken, vapidKey }  from './utilities/firebaseConfig'; 
import { onMessage } from 'firebase/messaging';
import { MessagePayload } from 'firebase/messaging';
import PaymentMenthods from './Presentation/admin/Pages/PaymentMenthods';
import SearchResults from './Presentation/customer/SearchResults';
import FoodOrdering from './Presentation/customer/Components/Layouts/Customer/FoodOrdering';
import Marketing from './Presentation/customer/Components/Layouts/Customer/Marketing';
import Advertising from './Presentation/customer/Components/Layouts/Customer/Advertising';
import AboutUs from './Presentation/customer/Components/Layouts/Customer/AboutUs';
import Affiliates from './Presentation/customer/Components/Layouts/Customer/Affiliates';
import PlanandPricing from './Presentation/customer/Components/Layouts/Customer/PlanandPricing';
import TermsCondition from './Presentation/customer/Components/Layouts/Customer/TermsCondition';
import PrivacyPolicy from './Presentation/customer/Components/Layouts/Customer/PrivacyPolicy';
import SupportPolicy from './Presentation/customer/Components/Layouts/Customer/SupportPolicy';
import RefundPolicy from './Presentation/customer/Components/Layouts/Customer/RefundPolicy';
import FAQs from './Presentation/customer/Components/Layouts/Customer/FAQs';
import Download from './Presentation/customer/Components/Layouts/Customer/Download';
import DeliveryService from './Presentation/customer/Components/Layouts/Customer/DeliveryService';
import ContactUs from './Presentation/customer/Components/Layouts/Customer/ContactUs';
import Feedback from './Presentation/customer/Components/Layouts/Customer/Feedback';
import FoodhutSearch from './Presentation/customer/FoodhutSearch';
import DishesPage from './Presentation/customer/DishesPage';
import BusinessPage from './Presentation/customer/BusinessPage';
import MenuPage from './Presentation/customer/MenuPage';
import AdminSettings from './Presentation/admin/Pages/AdminSettings';
import DriverOrders from './Presentation/driver/DriverOrders';
import DriverEarnings from './Presentation/driver/DriverEarnings';
import RouteOptimization from './Presentation/driver/RouteOptimization';
import ProofOfDelivery from './Presentation/driver/ProofOfDelivery';
import LiveTracking from './Presentation/driver/LiveTracking';
import IncidentReport from './Presentation/driver/IncidentReport';
import FailedDeliveries from './Presentation/driver/FailedDeliveries';
import DriverAccount from './Presentation/driver/DriverAccount';
import ResetPassword from './Presentation/app/ResetPassword';
import FoodhutDashboard from './Presentation/foodhutportal/FoodhutDashboard';
import Userbehavior from './Presentation/foodhutportal/Pages/Userbehavior';
import Demographics from './Presentation/foodhutportal/Pages/Demographics';
import Funnel from './Presentation/foodhutportal/Pages/Funnel';
import Operations from './Presentation/foodhutportal/Pages/Operations';
import Personalization from './Presentation/foodhutportal/Pages/Personalization';
import Retention from './Presentation/foodhutportal/Pages/Retention';
import Realtime from './Presentation/foodhutportal/Pages/Realtime';
import Sales from './Presentation/foodhutportal/Pages/Sales';
import MarketingCampaign from './Presentation/foodhutportal/Pages/MarketingCampaign';
import CustomerFeedback from './Presentation/foodhutportal/Pages/CustomerFeedback';
import NoResultsPage from './Presentation/customer/NoResultsPage';
import VisitorTrack from './Presentation/foodhutportal/Pages/VisitorTrack';
import Visitsperday from './Presentation/foodhutportal/Pages/Visitsperday';
import Promotions from './Presentation/admin/Pages/Promotions';
import SchedulePromotions from './Presentation/admin/Pages/SchedulePromotions';
import Promotionsdetails from './Presentation/app/Promotionsdetails';
import BusinessMenus from './Presentation/admin/Pages/BusinessMenus';
import OrderInProgress from './Presentation/driver/OrderInProgress';
import DriverOrderCompleted from './Presentation/driver/DriverOrderCompleted';

const win = window.sessionStorage;

// Register Service Worker for FCM
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      return navigator.serviceWorker.ready;
    })
    .then((registration) => {
      return getToken(messaging, { vapidKey });
    })
    .then((token) => {
      console.log('FCM Token:', token);
      win.setItem('FcmToken', token);
    })
    .catch((error) => {
      console.error('Service Worker registration or FCM token retrieval failed:', error);
    });
}


// Extend the type to include click_action
interface CustomNotificationPayload extends MessagePayload {
  notification?: {
    title?: string;
    body?: string;
    icon?: string;
    click_action?: string;
  };
  data?: {
    click_action?: string;
  };
}

// Handle foreground notifications
onMessage(messaging, (payload: CustomNotificationPayload) => {
  console.log('Foreground message received:', payload);

  const notificationTitle = payload.notification?.title || 'Notification';
  const notificationOptions = {
    body: payload.notification?.body,
    icon: payload.notification?.icon || '/default-icon.png',
  };

  const clickAction = payload.notification?.click_action || payload.data?.click_action || 'https://www.foodhut.mw/';

  if (Notification.permission === 'granted') {
    const notification = new Notification(notificationTitle, notificationOptions);

    notification.onclick = () => {
      window.location.href = clickAction;
    };
  }
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CartProvier>
      <Context>
        <Router>
          <Routes>
            <Route path="/" element={<LandingScreen />} />
            <Route path="/FoodCategory" element={<FoodCategory />} />
            <Route path="/ProductPage" element={<ProductPage />} />
            <Route path="/MenuFeedback" element={<MenuFeedback />} />
            <Route path="/Reviews" element={<Reviews />} />
            <Route path="/Signin" element={<Signin />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/PaymentPreferences" element={<PaymentPreferences />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/ExtendedSearch" element={<ExtendedSearch />} />
            <Route path="/SearchResults" element={<SearchResults />} />
            <Route path="/CustomerAccount" element={<Account />} />
            <Route path="/PersonalInfo" element={<UpdatePersonalInformation />} />
            <Route path="/PaymentInfo" element={<UpdatePaymentInfo />} />
            <Route path="/DeliveryInfo" element={<UpdateDeliveryInfo />} />
            <Route path="/Driverhome" element={<Driverhome />} />
            <Route path="/FoodStores/:BusinessID" element={<FoodStore />} />
            <Route path="/FoodStoreCategory/:Category" element={<FoodStoreCategory />} />
            <Route path="/MenuOptions" element={<MenuOptions />} />
            <Route path="/ShoppingCart" element={<ShoppingCartPage />} />
            <Route path="/Checkout" element={<CheckOut />} />
            <Route path="/PaymentConfirmation" element={<PaymentConfirmation />} />
            <Route path="/MyOrders" element={<MyOrders />} />
            <Route path="/TrackOrder" element={<TrackOrder />} />
            <Route path="/Favourites" element={<Favourites />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/AdminOrders" element={<AdminOrders />} />
            <Route path="/AdminAccount" element={<AdminAccount />} />
            <Route path="/PaymentMenthods" element={<PaymentMenthods />} />
            <Route path="/Promotions" element={<Promotions />} />
            <Route path="/Promotionsdetails" element={<Promotionsdetails />} />
            <Route path="/SchedulePromotions" element={<SchedulePromotions />} />
            <Route path="/AdminSettings" element={<AdminSettings />} />
            <Route path="/BusinessMenus" element={<BusinessMenus />} />
            <Route path="/Download" element={<Download />} />
            <Route path="/FAQs" element={<FAQs />} />
            <Route path="/RefundPolicy" element={<RefundPolicy />} />
            <Route path="/SupportPolicy" element={<SupportPolicy />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/TermsCondition" element={<TermsCondition />} />
            <Route path="/PlanandPricing" element={<PlanandPricing />} />
            <Route path="/Affiliates" element={<Affiliates />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Advertising" element={<Advertising />} />
            <Route path="/Marketing" element={<Marketing />} />
            <Route path="/FoodOrdering" element={<FoodOrdering />} />
            <Route path="/DeliveryService" element={<DeliveryService />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Feedback" element={<Feedback/>} />
            <Route path="/FoodhutSearch" element={<FoodhutSearch/>} />
            <Route path="/DishesPage" element={<DishesPage/>} />
            <Route path="/:businessSlug" element={<BusinessPage />} />
            <Route path="/menu/:menuSlug" element={<MenuPage />} />
            <Route path="/DriverOrders" element={<DriverOrders />} />
            <Route path="/OrderInProgress" element={<OrderInProgress />} />
            <Route path="/DriverOrderCompleted" element={<DriverOrderCompleted />} />
            <Route path="/DriverEarnings" element={<DriverEarnings />} />
            <Route path="/RouteOptimization" element={<RouteOptimization />} />
            <Route path="/ProofOfDelivery" element={<ProofOfDelivery />} />
            <Route path="/LiveTracking" element={<LiveTracking />} />
            <Route path="/IncidentReport" element={<IncidentReport />} />
            <Route path="/FailedDeliveries" element={<FailedDeliveries />} />
            <Route path="/DriverAccount" element={<DriverAccount />} /> 
            <Route path="/FoodhutDashboard" element={<FoodhutDashboard />} /> 
            <Route path="/Userbehavior" element={<Userbehavior />} /> 
            <Route path="/Demographics" element={<Demographics />} /> 
            <Route path="/Funnel" element={<Funnel />} /> 
            <Route path="/Operations" element={<Operations />} /> 
            <Route path="/Personalization" element={<Personalization />} /> 
            <Route path="/Retention" element={<Retention />} /> 
            <Route path="/Realtime" element={<Realtime />} />
            <Route path="/VisitorTrack" element={<VisitorTrack />} />
            <Route path="/Visitsperday" element={<Visitsperday />} />
            <Route path="/Sales" element={<Sales />} /> 
            <Route path="/MarketingCampaign" element={<MarketingCampaign />} /> 
            <Route path="/CustomerFeedback" element={<CustomerFeedback />} />            
            <Route path="/NoResultsPage" element={<NoResultsPage />} />        
          </Routes>
        </Router>
      </Context>
    </CartProvier>
  </React.StrictMode>
);