import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import IcecreamIcon from '@mui/icons-material/Icecream';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import LocalDiningIcon from '@mui/icons-material/LocalDining';



interface Props {
    Category: string;
    onSelectCategory: (category: string) => void;
    imageUrl?: string;  // Make imageUrl optional
}

const FoodhutChip = ({ Category, onSelectCategory, imageUrl }: Props) => {
  return (
    <Chip 
      label={Category} 
      onClick={() => onSelectCategory(Category)}
      //avatar={imageUrl ? <LocalDiningIcon  sx={{ marginLeft: '8px' }} /> : undefined} 
    />
  );
}

export default FoodhutChip;