import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { BASE_URL } from "../../../domain/Types/Config";
import { mail } from "../../../domain/Types/Email";

axiosRetry(axios, {
    retries: 3, // Number of retries
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
    shouldResetTimeout: true // Reset timeout on retries
  });

  export const SendEmail = async (Useremail: mail): Promise<string> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return SendEmail(Useremail); // Retry fetching data
      }
  
      //const url = `https://app.foodhut.mw/api/ShowBusinessesMenus/${BusinessID}`
      const url = `${BASE_URL}/email`;
      const response: AxiosResponse<string> = await axios.post(url, Useremail);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };