import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button } from '@mui/material';
import AppLayout from '../customer/Components/Layouts/App/AppLayout';
import { useLocation } from 'react-router-dom';
import { PasswordReset } from '../../domain/Types/PasswordReset';
import { ResetMyPassword } from '../../infrastructure/api/User/UserAPI';
import Swal from 'sweetalert2';

// Define the form input types
interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const { control, handleSubmit, formState: { errors }, watch } = useForm<ResetPasswordForm>();
  const [message, setMessage] = useState<string | null>(null); // Message state to show errors or invalid links
  const location = useLocation(); // Used to grab the token from URL

  // Password validation function (checks if passwords match)
  const validatePassword = (value: string) => {
    const password = watch('password');
    return value === password || 'Passwords do not match';
  };

  // Extract token and email from URL query parameters
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const email = query.get('email');

  console.log('Token:', token);
  console.log('Email:', email);

  // Define the type for the form data
  const onSubmit: SubmitHandler<ResetPasswordForm> = async (data) => {
    if (!token || !email) {
      setMessage('Invalid or expired reset link.');
      return;
    }

    const pwdreset: PasswordReset = {
      email: email,
      password: data.password,
      password_confirmation: data.password,
      token: token,
    };

    try {
      // Call API to reset password
      await ResetMyPassword(pwdreset);

      // Display success message
      Swal.fire({
        title: 'SUCCESS',
        text: 'Your password has been reset successfully. You can now log in with your new password.',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
      });

      // Optional: Redirect user to the login page after 2 seconds
      setTimeout(() => {
        window.location.href = '/'; // Change the route as needed
      }, 2000);
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An error occurred while resetting your password. Please try again later.');
    }
  };

  return (
    <AppLayout>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters long',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="New Password"
                variant="outlined"
                type="password"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
              />
            )}
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: 'Please confirm your password',
              validate: validatePassword,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Confirm New Password"
                variant="outlined"
                type="password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
              />
            )}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '1rem' }}
        >
          Reset Password
        </Button>
      </form>
      {message && <p style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
    </AppLayout>
  );
};

export default ResetPassword;