import React, { useState, useEffect } from 'react';
import {
  TextField,
  IconButton,
  CircularProgress,
  Backdrop,
  Typography,
  Popover,
  Grid,
  Box,
  Chip,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FrequentSearchItems, StoreSearchItem } from '../../../../infrastructure/api/FrequentSearch/FrequentSearchAPI';
import { fetchBusinessbyCategory, SearchBar } from '../../../../infrastructure/api/Business/BusinessAPI';
import { useNavigate } from 'react-router-dom';
import { Search } from '../../../../domain/Types/Search';

type FrequentSearchResponse = { [category: string]: string[] } | [] | undefined;

const MobileSearchBar: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [frequentSearches, setFrequentSearches] = useState<{ [category: string]: string[] }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [openLoader, setOpenLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverWidth, setPopoverWidth] = useState<number | string>('auto');
  const [shouldSearch, setShouldSearch] = useState<boolean>(false); // New flag to control search

  const navigate = useNavigate();
  const customColor = '#e88824';

  // Fetch frequent search items from the API
  useEffect(() => {
    const fetchFrequentSearches = async () => {
      try {
        const data: FrequentSearchResponse = await FrequentSearchItems();
        console.log('Frequent search data:', data); // Log the data for debugging

        if (Array.isArray(data)) {
          setFrequentSearches({});
        } else {
          setFrequentSearches(data ?? {});
        }
      } catch (error) {
        console.error('Error fetching frequent searches:', error);
        setFrequentSearches({});
      }
    };

    fetchFrequentSearches();
  }, []);

  // Store the search term and fetch search results
  const storeSearchTerm = async (term: string) => {
    const searchItem = {
      search: term,
      slug: term.replace(/\s+/g, '-').toLowerCase(),
      BusinessCategory: 'General',
      location: 'Not specified',
      foodtype: 'All',
      minimumPrice: 0,
      maximumprice: 9999,
    };

    try {
      await StoreSearchItem(searchItem);
      setLoading(true);
      setOpenLoader(true);

      const search: Search = {
        search: term,
        slug: '',
        BusinessCategory: term, // Adjust as needed
        location: '',
        foodtype: '',
        minimumPrice: 0,
        maximumprice: 0,
      };

      try {
        const response = await SearchBar(search);
        if (Array.isArray(response) && response.length > 0) {
          navigate('/FoodhutSearch', { state: { searchResults: response } });
        } else {
          console.log('No results found.');
          navigate('/NoResultsPage', { state: { searchTerm: term } });
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false);
        setOpenLoader(false);
      }
    } catch (error) {
      console.error('Error storing search term:', error);
    }
  };

  // Search effect that only triggers based on `shouldSearch`
  useEffect(() => {
    if (shouldSearch) {
      handleSearch(); // Perform search when flag is set
      setShouldSearch(false); // Reset flag
    }
  }, [shouldSearch]); // No `searchText` dependency here

  const handleSearch = () => {
    if (searchText.trim()) {
      storeSearchTerm(searchText);
      setAnchorEl(null); // Close the popover
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setPopoverWidth(event.currentTarget.clientWidth); // Set the popover width
    setAnchorEl(event.currentTarget); // Open the popover
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') setShouldSearch(true); // Trigger the search when Enter is pressed 
    //handleSearch();
  };

  const handleItemClick = (item: string) => {
    setSearchText(item);
    setAnchorEl(null); // Close the popover immediately
    setShouldSearch(true); // Trigger the search in useEffect
    //storeSearchTerm(item); // Trigger the search
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search..."
        fullWidth
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                onClick={handleSearch}
                style={{ cursor: 'pointer', color: customColor }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            width: popoverWidth,
            padding: 0,
            maxHeight: '300px',
            overflowY: 'auto',
            transition: 'width 0.2s ease-in-out',
          },
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        {/* Sticky Header */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#fff',
            zIndex: 2,
            p: 2,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Frequently Searched Categories
          </Typography>
        </Box>

        {/* Content: Display categories as Chips */}
        <Grid container spacing={2} sx={{ p: 2 }}>
          {Object.keys(frequentSearches).length > 0 ? (
            Object.keys(frequentSearches).map((category) => (
              <Grid item key={category}>
                <Chip
                  label={category}
                  onClick={() => handleItemClick(category)}
                  sx={{
                    padding: '8px',
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#e0e0e0' },
                    fontWeight: 'bold',
                  }}
                />
              </Grid>
            ))
          ) : (
            <Typography variant="body2" sx={{ p: 2 }}>
              No frequent searches available.
            </Typography>
          )}
        </Grid>
      </Popover>


      <Backdrop open={openLoader} style={{ zIndex: 9999 }}>
        <CircularProgress size={60} sx={{ color: customColor }} />
        <Typography variant="h6" style={{ marginTop: '16px', color: '#fff' }}>
          Searching...
        </Typography>
      </Backdrop>
    </div>
  );
};

export default MobileSearchBar;