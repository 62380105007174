import { createContext, Dispatch, ReactNode, useReducer, useEffect, useContext } from 'react';
import { ShoppingCart } from '../../domain/Types/ShoppingCart'; // Ensure ShoppingCart includes businessId

export type Action =
    | { type: 'ADD_TO_CART'; item: ShoppingCart }
    | { type: 'REMOVE_FROM_CART'; item: ShoppingCart }
    | { type: 'EMPTY_CART' }
    | { type: 'REDUCE_CART'; item: ShoppingCart }
    | { type: 'SET_SELECTED_BUSINESS'; businessId: number }
    | { type: 'CLEAR_SELECTED_BUSINESS' };

export interface ShoppingCartContextInterface {
    shoppingcart: ShoppingCart[];
    dispatch: Dispatch<Action>;
    getTotalItems: (shoppingcart: ShoppingCart[]) => number;
    getTotalPrice: (shoppingcart: ShoppingCart[]) => number;
    calculateVAT: (totalPrice: number) => number;
    calculateTotalPrice: (totalPrice: number, VAT: number) => number;
    getBusinessIds: (shoppingcart: ShoppingCart[]) => number[];
    selectedBusinessId: number | null;
}

interface ShoppingCartState {
    shoppingcart: ShoppingCart[];
    selectedBusinessId: number | null;
}

// Load state from localStorage
const loadStateFromLocalStorage = (): ShoppingCart[] => {
    try {
        const serializedState = localStorage.getItem('shoppingCart');
        if (serializedState === null) return [];
        return JSON.parse(serializedState);
    } catch (error) {
        console.error("Could not load state", error);
        return [];
    }
};

// Save state to localStorage
const saveStateToLocalStorage = (state: ShoppingCart[]) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('shoppingCart', serializedState);
    } catch (error) {
        console.error("Could not save state", error);
    }
};

// Calculate the total number of items in the cart
const getTotalItems = (shoppingcart: ShoppingCart[]): number => {
    return shoppingcart.reduce((total, item) => total + item.quantity, 0);
};

// Calculate the total price of items in the cart
const getTotalPrice = (shoppingcart: ShoppingCart[]): number => {
    return shoppingcart.reduce((total, item) => total + (item.quantity * item.price), 0);
};

// Calculate the VAT amount
const calculateVAT = (totalPrice: number): number => {
    const VATRate = 0.165; // 16.5% expressed as a decimal
    return totalPrice * VATRate;
};

// Calculate the total price including VAT
const calculateTotalPrice = (totalPrice: number, VAT: number): number => {
    return totalPrice + VAT;
};

// Get unique business IDs from the shopping cart
const getBusinessIds = (shoppingcart: ShoppingCart[]): number[] => {
    return Array.from(new Set(shoppingcart.map(item => item.businessId)));
};

const initialState: ShoppingCartState = {
    shoppingcart: loadStateFromLocalStorage(),
    selectedBusinessId: null,
};

const reducer = (state: ShoppingCartState, action: Action): ShoppingCartState => {
    switch (action.type) {
        case 'ADD_TO_CART':
            const existingItemIndex = state.shoppingcart.findIndex(item => item.id === action.item.id);            
            if (existingItemIndex !== -1) {
                const updatedCart = [...state.shoppingcart];

                //updatedCart[existingItemIndex].quantity += action.item.quantity;
                updatedCart[existingItemIndex].quantity += 1; // Increment by 1
                console.log(updatedCart[existingItemIndex].quantity);
                return { ...state, shoppingcart: updatedCart };
            } else {
                return { ...state, shoppingcart: [...state.shoppingcart, action.item] };
            }

        case 'REMOVE_FROM_CART':
            return { ...state, shoppingcart: state.shoppingcart.filter(item => item.id !== action.item.id) };

        case 'EMPTY_CART':
            if (state.selectedBusinessId !== null) {
                // Empty cart based on selected business ID
                return {
                    ...state,
                    shoppingcart: state.shoppingcart.filter(item => item.businessId !== state.selectedBusinessId),
                };
            } else {
                // Empty entire cart if no business is selected
                return { ...state, shoppingcart: [] };
            }

        case 'REDUCE_CART':
            const itemIndex = state.shoppingcart.findIndex(item => item.id === action.item.id);
            if (itemIndex !== -1) {
                const updatedCart = [...state.shoppingcart];
                updatedCart[itemIndex].quantity -= 1;
                if (updatedCart[itemIndex].quantity <= 0) {
                    updatedCart.splice(itemIndex, 1);
                }
                return { ...state, shoppingcart: updatedCart };
            }
            return state;

        case 'SET_SELECTED_BUSINESS':
            return { ...state, selectedBusinessId: action.businessId };

        case 'CLEAR_SELECTED_BUSINESS':
            return { ...state, selectedBusinessId: null };

        default:
            return state;
    }
};

export const ShoppingCartContext = createContext<ShoppingCartContextInterface>({
    shoppingcart: [],
    dispatch: () => {},
    getTotalItems: () => 0,
    getTotalPrice: () => 0,
    calculateVAT: () => 0,
    calculateTotalPrice: () => 0,
    getBusinessIds: () => [],
    selectedBusinessId: null,
});

type CartProviderProps = {
    children: ReactNode;
}

export default function CartProvider({ children }: CartProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        saveStateToLocalStorage(state.shoppingcart);
    }, [state.shoppingcart]);

    const contextValue = {
        shoppingcart: state.shoppingcart,
        dispatch,
        getTotalItems,
        getTotalPrice,
        calculateVAT,
        calculateTotalPrice,
        getBusinessIds,
        selectedBusinessId: state.selectedBusinessId,
    };

    return (
        <ShoppingCartContext.Provider value={contextValue}>
            {children}
        </ShoppingCartContext.Provider>
    );
}

export const ShoppingCartState = () => {
    return useContext(ShoppingCartContext);
};