import React from 'react'
import FHPortalLayout from './Layouts/FHPortalLayout'

const FoodhutDashboard = () => {
  return (
    <FHPortalLayout>
        FoodhutDashboard
    </FHPortalLayout>
  )
}

export default FoodhutDashboard