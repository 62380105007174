import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from '@mui/material';
import Withauth from '../../../../../utilities/WithAuthentication/Withauth';
import Footer from '../Customer/Footer';
import AdminNavigation from './AdminNavigation';



interface AdminLayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = (props) => {


  return (
    <>
      <div style={{ display: 'flex', position: 'relative' }}>
        

        {/* Main Content */}
        <div
          style={{
            flexGrow: 1,
            marginLeft: '0', // No margin shift required for modal drawer
          }}
        >
          <div className="container">
            {/* Admin Navigation */}
            <div className="row">
              <AdminNavigation />
            </div>

            {/* Page Content */}
            {props.children}

            <Divider orientation="horizontal" flexItem />

            {/* Footer */}
            <div className="row">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withauth(AdminLayout);