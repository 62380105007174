import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppLayout from './Components/Layouts/App/AppLayout';
import MenuCard from './Components/OtherComponents/MenuCard';
import ShoppingCartFloat from './Components/OtherComponents/ShoppingCartFloat';
import { ShoppingCartState } from '../../utilities/Context/ShoppingCartContext';
import { Menu } from '../../domain/Types/Menu';
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import { fetchMenuData } from '../../infrastructure/api/Menus/MenuAPI';
import { Grid, Typography, Skeleton, CardContent, CardActions, Card, Divider, Box } from '@mui/material';
import AddToCartModal from '../app/GeneralComponents/AddToCartModal';

const FoodStore = () => {
  const [Menus, setMenus] = useState<Menu[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null);

  const businessIDParam = useParams().BusinessID;
  const BusinessID: number = businessIDParam ? parseInt(businessIDParam) : NaN;
  const navigate = useNavigate();
  const win = window.sessionStorage;
  const userIdString = win.getItem('userid');
  const userId = userIdString ? parseInt(userIdString) : 0;
  const { dispatch } = ShoppingCartState();

  const groupMenusByCategory = (menus: Menu[]) => {
    return menus.reduce((acc: { [key: number]: Menu[] }, menu) => {
      const { menucategory_id } = menu;
      if (!acc[menucategory_id]) {
        acc[menucategory_id] = [];
      }
      acc[menucategory_id].push(menu);
      return acc;
    }, {});
  };

  const handleAddtoCart = (menu: Menu) => {
    const cartitem: ShoppingCart = {
      id: menu.id,
      user_id: userId,
      session_id: menu.business_id,
      order_id: 1,
      menu_id: menu.id,
      imagepath: menu.image_path,
      status: menu.description,
      quantity: 1,
      total: menu.price,
      name: menu.name,
      price: menu.price,
      productattributevalues: [],
      businessId: menu.business_id,
      businessName: menu.business.name,
    };

    dispatch({ type: 'ADD_TO_CART', item: cartitem });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMenuData(BusinessID);
        console.log(data);
        setMenus(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [BusinessID]);

  const handleOpenModal = (item: Menu) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  const groupedMenus = Menus ? groupMenusByCategory(Menus) : {};

  return (
    <AppLayout>
      <div className="font-bold mb-1">MENU</div>
      <Grid container spacing={3}>
        <Grid item md={9} container direction="column" spacing={3}>
          {loading ? (
            <Grid container spacing={3}>
              {Array.from(new Array(8)).map((_, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <Card sx={{ borderRadius: '16px', margin: 2 }}>
                    <Skeleton 
                      variant="rectangular" 
                      height={140} 
                      sx={{ borderRadius: '16px 16px 0 0' }} 
                    />
                    <CardContent>
                      <Skeleton width="80%" height={30} sx={{ marginBottom: 1 }} />
                      <Skeleton width="60%" height={20} sx={{ marginBottom: 1 }} />
                      <Skeleton width="50%" height={20} sx={{ marginBottom: 2 }} />
                      <Skeleton width="30%" height={20} />
                    </CardContent>
                    <CardActions>
                      <Skeleton width="40%" height={30} />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : Menus && Object.keys(groupedMenus).length > 0 ? (
            Object.entries(groupedMenus).map(([categoryId, menus]) => (
              <Box key={categoryId} marginBottom={4}>
                <Typography variant="h5" gutterBottom>
                  Category {categoryId}
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid container spacing={3}>
                  {menus.map((menu: Menu) => (
                    <Grid key={menu.id} item xs={12} sm={6} md={3}>
                      <MenuCard
                        FoodMenu={menu}
                        onViewOption={() => navigate('/MenuOptions')}
                        onSelectmenu={() => handleOpenModal(menu)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary">
              No Menus available
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <ShoppingCartFloat />
        </Grid>
      </Grid>
      {selectedItem && (
        <AddToCartModal
          open={openModal}
          handleClose={handleCloseModal}
          selectedItem={selectedItem}
          userId={userId}
        />
      )}
    </AppLayout>
  );
};

export default FoodStore;