import AppLayout from "./Components/Layouts/App/AppLayout";
import CustomerLayout from "./Components/Layouts/Customer/CustomerLayout";
import { Link } from "react-router-dom";

const Account = () => {
    const win = window.sessionStorage;

    return(
        <AppLayout>
            <span className='font-bold'>My Account</span><br />
                Welcome {win.getItem("fname")} {win.getItem("lname")}
                <ul>
                    <li><Link to="/PersonalInfo">Personal Information</Link></li>
                    <li><Link to="/PaymentInfo">Payment Information</Link></li>
                    <li><Link to="/DeliveryInfo">Delivery Information</Link></li>
                </ul>
        </AppLayout>
    )

}

export default Account