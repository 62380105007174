import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FoodHutBtn from '../CommmonComponents/FoodHutBtn';
import { Box, Badge, IconButton, Rating } from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';
import { Business } from '../../../../domain/Types/Business';

interface Props {
    foodstore: Business;
    onSelectfoodstore: (foodstore: Business) => void
}

/*export type Business = {
    category: string;
    category_id:number;
    cellnumber:string;
    contactperson:string;
    description:string;
    email:string;
    id:string;
    image_path:string;
    name:string;
    slu
  }*/

const BusinessCard = ({foodstore, onSelectfoodstore}: Props) => {
  return (
              <Card
              sx={{
                borderRadius: '16px 16px 8px 8px', // More rounded at the top (16px) and less rounded at the bottom (8px)
              }}
              >
                <Box position="relative">
                  <Badge
                    badgeContent=""
                    color="primary"
                    sx={{
                      position: 'absolute',
                      top: 15,
                      left: 15,
                      zIndex: 1,
                      padding: '0.5rem',
                      borderRadius: '8px',
                      backgroundColor: '#e88824',
                      color: 'white',
                    }}
                  />
                  <CardMedia
                  component="img"
                  image={`https://app.foodhut.mw/Admin/public/images/${foodstore.image_path}`}
                  alt={foodstore.name}
                  sx={{
                    height: 140,
                    width: '100%',
                    objectFit: 'cover', // Ensures the image covers the area without distortion
                    objectPosition: 'center', // Centers the image if it's larger
                    cursor: 'pointer',
                  }}
                  onClick={()=>{onSelectfoodstore(foodstore)}}
                />
                </Box>
                <CardContent sx={{ padding: 1 }}>
                  <Typography variant="h6" component="div" 
                  sx={{ marginBottom: 0.5, fontStyle: 'normal', fontWeight: "bold", cursor: 'pointer' }}
                  onClick={()=>{onSelectfoodstore(foodstore)}}
                  >
                  {foodstore.name}
                  </Typography>
                  <Rating value={3.8} precision={0.5} readOnly sx={{ marginBottom: 0.5 }}/>
                  <Box mt={0}>
                    <Typography variant="h6" component="div" color="textPrimary" sx={{ marginBottom: 0.5 }}>
                      {foodstore.description.length > 100 
                        ? `${foodstore.description.substring(0, 100)}...` 
                        : foodstore.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
  )
}

export default BusinessCard